import React, { useState } from "react";
import { Modal, Form, Row, Col, Button, Container } from "react-bootstrap";
import { Table } from "antd";

const ViewResignationLetterRS = () => {
  const [data, setData] = useState([
    {
      key: 1,
      employeeName: "John Doe",
      department: "IT",
      resignationDate: "10-10-24",
      approvalStatusHR: "Pending",
      approvalStatusRSSir: "Pending",
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Resignation Date",
      dataIndex: "resignationDate",
      key: "resignationDate",
    },
    {
      title: "View",
      key: "view",
      render: (_, record) => (
        <Button variant="dark" onClick={handleShow}>
          View
        </Button>
      ),
    },
    {
      title: "Approval Status HR",
      dataIndex: "approvalStatusHR",
      key: "approvalStatusHR",
    },
    {
      title: "Approval Status RS Sir",
      dataIndex: "approvalStatusRSSir",
      key: "approvalStatusRSSir",
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Container className="mt-4">
          {/* Table Section */}
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.key}
            loading={isLoading}
            pagination={{ pageSize: 5 }}
          />
        </Container>

        {/* Modal for Resignation Letter Form */}
        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Resignation Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="dateOfResignation">
                    <Form.Label>Date of Resignation</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter date of resignation"
                      defaultValue="10-10-24"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="lastWorkingDate">
                    <Form.Label>Last Working Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter last working date"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="supervisorName">
                    <Form.Label>Supervisor/TL Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter supervisor name"
                      defaultValue="Tanmay"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="resignationLetter" className="mb-3">
                <Form.Label>Resignation Letter</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Write your resignation letter here..."
                />
              </Form.Group>

              <Form.Group controlId="signature" className="mb-3">
                <Form.Label>Signature</Form.Label>
                <Form.Control type="file" accept="image/*" />
              </Form.Group>

              <Form.Group controlId="remarks" className="mb-3">
                <Form.Label>Remarks (if any)</Form.Label>
                <Form.Control type="text" placeholder="Enter remarks" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="danger"
              className="px-4"
              style={{ backgroundColor: "#FF6B6B", borderColor: "#FF6B6B" }}
              onClick={handleClose}
            >
              Approve
            </Button>
            <Button
              variant="secondary"
              className="px-4"
              style={{ backgroundColor: "#4A4A4A", borderColor: "#4A4A4A" }}
              onClick={handleClose}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ViewResignationLetterRS;
