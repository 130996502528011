import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  attendance,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const HREmployeeAttendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);

  const { id } = useParams();
  // Add filter states
  const [selectedMonth, setSelectedMonth] = useState(() => ({
    value: moment().month() + 1,
    label: moment().format("MMMM"),
  }));
  const [selectedYear, setSelectedYear] = useState(() => ({
    value: moment().year(),
    label: moment().year().toString(),
  }));

  // Create month and year options
  const months = moment.months().map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  const years = Array.from({ length: 5 }, (_, i) => {
    const year = moment().year() - i;
    return { value: year, label: year.toString() };
  });

  // Transform attendance data for table
  const tableData = attendanceData.map((record, index) => {
    const isToday = moment(record.date).isSame(moment(), "day");
    const hasClockOut = record.timings[0]?.clock_out;

    return {
      key: index,
      date: moment(record.date).format("DD-MM-YYYY"),
      clockIn: moment(record.clock_in).format("hh:mm A"),
      clockOut: record.timings[0]?.clock_out
        ? moment(record.timings[0].clock_out).format("hh:mm A")
        : isToday
        ? "-"
        : "Not clocked out",
      totalHours: hasClockOut
        ? record.total_working_hours.split(":").slice(0, 2).join("h ") + "m"
        : isToday
        ? "-"
        : "Not clocked out",
      status: record.timings[0]?.status || "-",
      breaks: record.timings.filter((t) => t.status === "Away").length,
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) =>
        moment(a.date, "DD-MM-YYYY").unix() -
        moment(b.date, "DD-MM-YYYY").unix(),
    },
    {
      title: "Clock In",
      dataIndex: "clockIn",
      sorter: (a, b) =>
        moment(a.clockIn, "hh:mm A").unix() -
        moment(b.clockIn, "hh:mm A").unix(),
    },
    {
      title: "Clock Out",
      dataIndex: "clockOut",
      sorter: (a, b) => {
        if (a.clockOut === "Not clocked out") return 1;
        if (b.clockOut === "Not clocked out") return -1;
        if (a.clockOut === "-") return 1;
        if (b.clockOut === "-") return -1;
        return (
          moment(a.clockOut, "hh:mm A").unix() -
          moment(b.clockOut, "hh:mm A").unix()
        );
      },
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      render: (text) => text,
      sorter: (a, b) => {
        if (a.totalHours === "Not clocked out") return 1;
        if (b.totalHours === "Not clocked out") return -1;
        if (a.totalHours === "-") return 1;
        if (b.totalHours === "-") return -1;
        const [aHours, aMinutes] = a.totalHours
          .replace("h ", ":")
          .replace("m", "")
          .split(":");
        const [bHours, bMinutes] = b.totalHours
          .replace("h ", ":")
          .replace("m", "")
          .split(":");
        return (
          parseInt(aHours) * 60 +
          parseInt(aMinutes) -
          (parseInt(bHours) * 60 + parseInt(bMinutes))
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badge ${
            text === "Present"
              ? "bg-success"
              : text === "Away"
              ? "bg-warning"
              : "bg-secondary"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Breaks Taken",
      dataIndex: "breaks",
      sorter: (a, b) => a.breaks - b.breaks,
    },
  ];

  // Add new function to fetch employee details
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(`${employeeList}${id}/`);
      console.log(response.data);
      if (response.data) {
        setEmployeeDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
      setError(
        error.response?.data?.message ||
          "Failed to fetch employee details. Please try again."
      );
    }
  };

  // Update useEffect to fetch both employee details and attendance
  useEffect(() => {
    fetchEmployeeDetails();
    fetchAttendanceData(selectedMonth.value, selectedYear.value);
  }, [id]);

  // Update fetchAttendanceData to handle errors properly
  const fetchAttendanceData = async (month, year, page = 1, pageSize = 10) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(
        `${attendance}?user=${id}&month=${month}&year=${year}&page=${page}&page_size=${pageSize}`
      );

      if (response.data) {
        setAttendanceData(response.data.results || []);
        setPagination({
          current: page,
          pageSize: pageSize,
          total: response.data.count || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setError(
        error.response?.data?.message ||
          "Failed to fetch attendance data. Please try again."
      );
      setAttendanceData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle filter changes
  const handleSearch = () => {
    fetchAttendanceData(selectedMonth.value, selectedYear.value);
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      minHeight: 45,
      borderColor: "#e3e3e3",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        color: "#fff",
      },
    }),
  };

  // Add pagination handling
  const handleTableChange = (pagination, filters, sorter) => {
    setIsLoading(true);
    fetchAttendanceData(
      selectedMonth.value,
      selectedYear.value,
      pagination.current,
      pagination.pageSize
    );
  };

  // Add pagination state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/hr/attendance">Attendance</Link>
                  </li>
                  <li className="breadcrumb-item active">Employee Details</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Employee Details Card */}
          <div className="card mb-4">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  {employeeDetails ? (
                    <div className="d-flex align-items-center">
                      <h4 className="text-primary mb-0">
                        {employeeDetails.employee_name}
                      </h4>
                      <span className="mx-2">•</span>
                      <p className="text-muted mb-0">
                        {
                          employeeDetails.employment_details[0]
                            ?.designation_detail?.name
                        }
                        {" - "}
                        {
                          employeeDetails.employment_details[0]
                            ?.department_detail?.name
                        }
                      </p>
                    </div>
                  ) : (
                    <span className="text-muted">
                      Loading employee details...
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Filter Section */}
          <div className="row filter-row mb-4">
            <div className="col-sm-4">
              <div className="input-block form-focus select-focus">
                <Select
                  value={selectedMonth}
                  onChange={(option) => {
                    setSelectedMonth(option);
                    setError(null); // Clear error on filter change
                  }}
                  options={months}
                  styles={customSelectStyles}
                  className="select"
                  placeholder="Select Month"
                  isSearchable={false}
                />
                <label className="focus-label">Month</label>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="input-block form-focus select-focus">
                <Select
                  value={selectedYear}
                  onChange={(option) => {
                    setSelectedYear(option);
                    setError(null); // Clear error on filter change
                  }}
                  options={years}
                  styles={customSelectStyles}
                  className="select"
                  placeholder="Select Year"
                  isSearchable={false}
                />
                <label className="focus-label">Year</label>
              </div>
            </div>

            <div className="col-sm-4">
              <button
                className="btn btn-success w-100"
                onClick={handleSearch}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
                {isLoading ? "Loading..." : "Search"}
              </button>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div className="alert alert-danger mb-4" role="alert">
              {error}
            </div>
          )}

          {/* No Results Message */}
          {!isLoading && attendanceData.length === 0 && !error && (
            <div className="alert alert-info mb-4" role="alert">
              No attendance records found for the selected month and year.
            </div>
          )}

          {/* Table Section */}
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  loading={isLoading}
                  onChange={handleTableChange}
                  pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  className="table-striped"
                  locale={{
                    emptyText: isLoading ? "Loading..." : "No data available",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HREmployeeAttendance;
