import React, { useState, useEffect, useCallback } from "react";
import { Table, Select, Spin } from "antd";
import moment from "moment";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  attendance,
  departmentList,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const DailyLog = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axiosInstance.get(departmentList);
        // Filter out "test department" from the departments list
        const filteredDepartments = response.data.filter(
          (dept) => dept.name.toLowerCase() !== "test department"
        );
        setDepartments(filteredDepartments);
      } catch (error) {
        console.error("Error fetching departments:", error);
        toast.error("Failed to fetch departments");
      }
    };
    fetchDepartments();
  }, []);

  // Transform attendance data for table
  const tableData = attendanceData.map((record) => ({
    key: record.id,
    employeeName: record.user_details?.employee_name || "-",
    department:
      record.user_details?.employment_details[0]?.department_detail?.name ||
      "-",
    date: moment(record.date).format("DD-MM-YYYY"),
    clockIn: moment(record.clock_in).format("hh:mm A"),
    clockOut: record.timings[0]?.clock_out
      ? moment(record.timings[0].clock_out).format("hh:mm A")
      : "-",
    status: record.timings[0]?.clock_out
      ? "Clocked Out"
      : record.timings[0]?.status || "-",
    breaks: record.timings.filter((t) => t.status === "Away").length,
    lastTiming: record.timings[0]
      ? {
          status: record.timings[0].clock_out
            ? "Clocked Out"
            : record.timings[0].status,
          time: record.timings[0].clock_out
            ? moment(record.timings[0].clock_out).format("hh:mm A")
            : moment(record.timings[0].clock_in).format("hh:mm A"),
        }
      : null,
  }));

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Clock In",
      dataIndex: "clockIn",
      sorter: (a, b) =>
        moment(a.clockIn, "hh:mm A").unix() -
        moment(b.clockIn, "hh:mm A").unix(),
    },
    {
      title: "Clock Out",
      dataIndex: "clockOut",
      sorter: (a, b) => {
        if (a.clockOut === "-") return 1;
        if (b.clockOut === "-") return -1;
        return (
          moment(a.clockOut, "hh:mm A").unix() -
          moment(b.clockOut, "hh:mm A").unix()
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badge ${
            text === "Present"
              ? "bg-success"
              : text === "Away"
              ? "bg-warning"
              : text === "Clocked Out"
              ? "bg-secondary"
              : "bg-secondary"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Took Break/Came Back",
      dataIndex: "lastTiming",
      render: (lastTiming, record) => {
        if (!lastTiming || record.breaks === 0) return "-";
        return (
          <div>
            <span
              className={`badge ${
                lastTiming.status === "Away" ? "bg-warning" : "bg-success"
              } me-2`}
            >
              {lastTiming.status === "Away" ? "Break" : "Return"}
            </span>
            {lastTiming.time}
          </div>
        );
      },
    },
    {
      title: "Total Breaks",
      dataIndex: "breaks",
      sorter: (a, b) => a.breaks - b.breaks,
    },
  ];

  const fetchAttendanceData = useCallback(
    async (page = 1, pageSize = 10) => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams({
          page,
          page_size: pageSize,
          date: moment().format("YYYY-MM-DD"),
        });

        if (selectedDepartment && selectedDepartment !== "all") {
          params.append("department", selectedDepartment);
        }

        const response = await axiosInstance.get(
          `${attendance}?${params.toString()}`
        );
        setAttendanceData(response.data.results);
        setPagination((prev) => ({
          ...prev,
          current: page,
          total: response.data.count,
        }));
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        toast.error("Failed to fetch attendance data");
      } finally {
        setIsLoading(false);
      }
    },
    [selectedDepartment]
  );

  useEffect(() => {
    fetchAttendanceData(pagination.current, pagination.pageSize);
  }, [selectedDepartment, fetchAttendanceData]);

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    setPagination({ ...pagination, current: 1 }); // Reset to first page
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Daily Attendance Log</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/admin/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Daily Log</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <Select
                  placeholder="Select Department"
                  onChange={handleDepartmentChange}
                  value={selectedDepartment}
                  style={{ width: "200px" }}
                  allowClear
                >
                  <Select.Option value="all">All Departments</Select.Option>
                  {departments.map((dept) => (
                    <Select.Option key={dept.id} value={dept.id}>
                      {dept.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {/* Table Section */}
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  loading={isLoading}
                  pagination={{
                    ...pagination,
                    onChange: (page, pageSize) => {
                      fetchAttendanceData(page, pageSize);
                    },
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  }}
                  className="table-striped"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyLog;
