import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Card, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("privacy");
  const privacyPoints = [
    {
      title: "1. Introduction",
      content: `HABOT ("we," "our," or "us") values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard the data you provide when using the HABOT Internal Employee App ("the App").
  
      The App is intended solely for HABOT employees to facilitate login, logout, leave applications, and other internal processes. By using the App, you agree to the practices described in this policy.`,
    },
    {
      title: "2. Information We Collect",
      content: `We collect the following types of information to ensure the proper functioning of the App:
  
      a. Personal Information:
      • Full Name
      • Employee ID
      • Email Address
      • Contact Number
  
      b. Usage Data:
      • Login and logout timestamps
      • Leave application details (e.g., type of leave, dates, and reason)
      • App usage activity logs (to monitor app performance and detect issues)
  
      c. Device Information:
      • Device model and operating system
      • IP address
      • App version`,
    },
    {
      title: "3. How We Use Your Information",
      content: `The data collected is used for the following purposes:
      • To authenticate and manage employee accounts
      • To facilitate and track attendance, courses, policies, appraisal, resignation, QPA and leave processes
      • To maintain internal records and manage administrative tasks
      • To improve the functionality and user experience of the App
      • To comply with legal or regulatory requirements`,
    },
    {
      title: "4. Information Sharing and Disclosure",
      content: `We do not sell, rent, or share your personal information with third parties, except in the following cases:
      • With authorized HABOT personnel or departments for internal operations
      • With service providers or contractors who assist in maintaining or enhancing the App (e.g., IT support), under strict confidentiality agreements
      • When required by law, legal processes, or to protect the rights, safety, and property of HABOT or its employees`,
    },
    {
      title: "5. Data Security",
      content: `We take the security of your data seriously and implement appropriate technical and organizational measures to protect it. These include:
      • Secure encryption of sensitive data
      • Access controls to limit data access to authorized personnel
      • Regular security audits and updates
  
      However, no system is completely secure, and we cannot guarantee absolute data security. Please notify us immediately if you suspect any unauthorized access or data breach.`,
    },
    {
      title: "6. Data Retention",
      content: `We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. When data is no longer needed, we securely delete or anonymize it.`,
    },
    {
      title: "7. Your Rights",
      content: `As an employee, you have the following rights concerning your personal data:
      • Access: Request access to the information we hold about you
      • Correction: Request corrections to inaccurate or incomplete information
      • Deletion: Request the deletion of your data where it is no longer necessary
      • Objection: Object to the processing of your data for specific purposes
  
      To exercise these rights, please contact us.`,
    },
    {
      title: "8. Changes to This Privacy Policy",
      content: `We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted in the App, and we encourage you to review this Policy periodically.`,
    },
    {
      title: "9. Contact Us",
      content: (
        <div>
          If you have any questions or concerns about this Privacy Policy or how
          your data is handled, please contact us:
          <br />
          <br />
          Email:{" "}
          <a
            href="mailto:webteam4@habot.io"
            style={{ color: "#1890ff", textDecoration: "underline" }}
          >
            webteam4@habot.io
          </a>
          <br />
          <br />
          Address: Jewellery & Gemplex 1 - DMCC-PH2-J&GPlexS - 1O-12-06 - I -
          Dubai - United Arab Emirates
          <br />
          <br />
          By using the HABOT Internal Employee App, you acknowledge that you
          have read and understood this Privacy Policy and agree to its terms.
        </div>
      ),
    },
  ];

  const terms = [
    {
      title: "1. Introduction",
      content: `Welcome to the HABOT Internal Employee App ("the App"). These Terms and Conditions ("Terms") govern your use of the App, provided by HABOT ("we," "our," or "us"). By accessing or using the App, you agree to these Terms. If you do not agree, you must discontinue using the App immediately.`,
    },
    {
      title: "2. Eligibility",
      content: `The App is intended exclusively for current employees of HABOT. By using the App, you confirm that you are an authorized employee and will only use the App for legitimate work-related purposes.`,
    },
    {
      title: "3. Use of the App",
      content: `As a user of the App, you agree to:
      • Use the App only for purposes authorized by HABOT
      • Provide accurate and up-to-date information during registration and use of the App
      • Refrain from using the App for any illegal, unauthorized, or unethical purposes
      • Maintain the confidentiality of your login credentials and notify HABOT immediately if you suspect unauthorized access to your account`,
    },
    {
      title: "4. Prohibited Conduct",
      content: `You may not:
      • Share your login credentials with unauthorized individuals
      • Attempt to access accounts, systems, or data you are not authorized to access
      • Use the App to distribute malicious software, spam, or other harmful content
      • Interfere with the functionality or security of the App`,
    },
    {
      title: "5. Intellectual Property",
      content: `All content, features, and functionalities of the App, including but not limited to software, text, images, and logos, are the exclusive property of HABOT. You are granted a limited, non-exclusive, and non-transferable license to use the App for its intended purposes. You may not copy, modify, distribute, or create derivative works based on the App without prior written consent from HABOT.`,
    },
    {
      title: "6. Privacy",
      content: `Your use of the App is subject to the HABOT Internal Employee App Privacy Policy, which explains how we collect, use, and protect your data. By using the App, you acknowledge that you have read and agree to the Privacy Policy.`,
    },
    {
      title: "7. Termination",
      content: `HABOT reserves the right to suspend or terminate your access to the App at any time without notice for any reason, including but not limited to:
      • Violation of these Terms
      • Misuse or unauthorized use of the App
      • Employment termination or separation from HABOT
      
      Upon termination, your access to the App and related data may be restricted or permanently removed.`,
    },
    {
      title: "8. Limitation of Liability",
      content: `To the fullest extent permitted by law, HABOT is not liable for any damages, losses, or expenses arising from your use of the App, including but not limited to:
      • Loss of data or unauthorized access
      • Service interruptions or technical issues
      • Errors or inaccuracies in the App's functionality`,
    },
    {
      title: "9. Modifications to the App and Terms",
      content: `HABOT reserves the right to update or modify the App and these Terms at any time. Any changes will be communicated through the App or other official channels. Your continued use of the App after such changes constitutes acceptance of the updated Terms.`,
    },
    {
      title: "10. Governing Law and Dispute Resolution",
      content: `These Terms are governed by and construed in accordance with the laws. Any disputes arising out of or related to these Terms or the App will be resolved through arbitration, as applicable.`,
    },
    {
      title: "11. Contact Us",
      content: (
        <div>
          If you have any questions or concerns about these Terms, please
          contact us:
          <br />
          <br />
          Email:{" "}
          <a
            href="mailto:webteam4@habot.io"
            style={{ color: "#1890ff", textDecoration: "underline" }}
          >
            webteam4@habot.io
          </a>
          <br />
          <br />
          Address: Jewellery & Gemplex 1 - DMCC-PH2-J&GPlexS - 1O-12-06 - I -
          Dubai - United Arab Emirates
          <br />
          <br />
          By using the HABOT Internal Employee App, you acknowledge that you
          have read, understood, and agree to these Terms and Conditions.
        </div>
      ),
    },
  ];

  return (
    <div style={{ background: "#f0f5ff", minHeight: "100vh" }}>
      <div className="content container-fluid py-5">
        <Card
          className="shadow-sm"
          style={{
            borderTop: "4px solid #072f57",
          }}
        >
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
            style={{
              marginBottom: 24,
              borderColor: "#072f57",
              color: "#072f57",
            }}
            className="back-button"
          >
            Back to Login
          </Button>

          <div className="policy-container">
            {/* Toggle Buttons */}
            <div className="toggle-container">
              <button
                className={`toggle-button ${
                  activeSection === "privacy" ? "active" : ""
                }`}
                onClick={() => setActiveSection("privacy")}
              >
                Privacy Policy
              </button>
              <button
                className={`toggle-button ${
                  activeSection === "terms" ? "active" : ""
                }`}
                onClick={() => setActiveSection("terms")}
              >
                Terms & Conditions
              </button>
            </div>

            {/* Content Container */}
            <div className="content-container">
              <div
                className={`content-panel ${
                  activeSection === "privacy" ? "active" : ""
                }`}
              >
                <Typography>
                  <Title level={1} className="policy-title">
                    Privacy Policy
                  </Title>
                  <Title level={3} className="policy-subtitle">
                    HABOT Internal Employee App
                  </Title>
                  <Paragraph className="last-updated">
                    Last Updated: January 10, 2024
                  </Paragraph>

                  <div className="terms-grid">
                    {privacyPoints.map((point, index) => (
                      <Card key={index} className="term-card" bordered={false}>
                        <Title level={4} className="term-title">
                          {point.title}
                        </Title>
                        <div className="term-content">
                          {React.isValidElement(point.content) ? (
                            point.content
                          ) : (
                            <Paragraph style={{ whiteSpace: "pre-line" }}>
                              {point.content}
                            </Paragraph>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>
                </Typography>
              </div>

              <div
                className={`content-panel ${
                  activeSection === "terms" ? "active" : ""
                }`}
              >
                <Typography>
                  <Title level={1} className="policy-title">
                    Terms and Conditions
                  </Title>
                  <Title level={3} className="policy-subtitle">
                    HABOT Internal Employee App
                  </Title>
                  <Paragraph className="last-updated">
                    Last Updated: January 10, 2024
                  </Paragraph>

                  <div className="terms-grid">
                    {terms.map((term, index) => (
                      <Card key={index} className="term-card" bordered={false}>
                        <Title level={4} className="term-title">
                          {term.title}
                        </Title>
                        <div className="term-content">
                          {React.isValidElement(term.content) ? (
                            term.content
                          ) : (
                            <Paragraph style={{ whiteSpace: "pre-line" }}>
                              {term.content}
                            </Paragraph>
                          )}
                        </div>
                      </Card>
                    ))}
                  </div>
                </Typography>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <style jsx>{`
        .policy-container {
          max-width: 1200px;
          margin: 0 auto;
        }

        .toggle-container {
          display: flex;
          justify-content: center;
          margin-bottom: 2rem;
          gap: 1rem;
          flex-wrap: wrap;
        }

        .toggle-button {
          padding: 12px 24px;
          border: 2px solid #072f57;
          background: transparent;
          color: #072f57;
          font-size: 16px;
          font-weight: 500;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          width: auto;
          min-width: 150px;
        }

        .toggle-button.active {
          background: #072f57;
          color: white;
        }

        .toggle-button:hover {
          background: rgba(7, 47, 87, 0.1);
        }

        .toggle-button.active:hover {
          background: #072f57;
        }

        .content-container {
          position: relative;
          height: auto;
          min-height: 500px;
        }

        .content-panel {
          position: relative;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          height: 0;
          overflow: hidden;
        }

        .content-panel.active {
          opacity: 1;
          visibility: visible;
          height: auto;
          overflow: visible;
        }

        .policy-title {
          color: #072f57;
          font-size: calc(1.5rem + 1vw);
          font-weight: 600;
          text-align: center;
          margin-bottom: 8px;
        }

        .policy-subtitle {
          color: #072f57;
          text-align: center;
          font-weight: 400;
          margin-top: 0;
          font-size: calc(1rem + 0.5vw);
        }

        .last-updated {
          text-align: center;
          font-size: 1rem;
          color: #6c757d;
          margin-bottom: 32px;
        }

        .terms-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
          gap: 24px;
          padding: 16px;
        }

        .term-card {
          background: white;
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(7, 47, 87, 0.08);
          transition: all 0.3s ease;
          border-top: 4px solid #072f57;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .term-card:hover {
          transform: translateY(-4px);
          box-shadow: 0 8px 24px rgba(7, 47, 87, 0.12);
        }

        .term-title {
          color: #072f57 !important;
          margin-bottom: 16px !important;
          font-size: calc(1rem + 0.25vw) !important;
        }

        .term-content {
          color: #4a5568;
          line-height: 1.6;
          margin-bottom: 0;
          flex-grow: 1;
        }

        .term-content a {
          color: #1890ff;
          text-decoration: underline;
          transition: color 0.3s ease;
        }

        .term-content a:hover {
          color: #40a9ff;
        }

        @media (max-width: 768px) {
          .toggle-container {
            padding: 0 16px;
          }

          .terms-grid {
            padding: 8px;
            gap: 16px;
          }

          .term-card {
            margin-bottom: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default PrivacyPolicy;
