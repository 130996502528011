import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  pdcaassigntask,
  pdcaEd,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

function EmployeePDCAViewDocument() {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axiosInstance.get(`${pdcaassigntask}${id}/`);
        setTask(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskData();
  }, [id]);

  const handleUploadAndSubmit = async () => {
    if (!documentFile) {
      toast.error("Please select a document to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("document", documentFile);
    formData.append("pdca", id);

    try {
      const uploadResponse = await axiosInstance.post(`${pdcaEd}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload Response:", uploadResponse.data);
      // toast.success("Document uploaded successfully!");

      const submitResponse = await axiosInstance.patch(`${pdcaassigntask}${id}/`, {
        submit_status: true,
      });
      console.log("Submit Response:", submitResponse.data);
      toast.success("Task submitted successfully!");
    } catch (err) {
      console.error("Error in upload and submit:", err);
      toast.error("Failed to upload and submit the task. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Form>
          <Form.Group className="mb-3" controlId="resignationLetter">
            <Button>View Assignment Task</Button>
            <div className="mt-4">
              <Form.Label>PDCA Task</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                value={task ? task.pdca_task : ""}
                readOnly
              />
            </div>
            {task?.pdca_ed[0]?.approve_status && (
              <div className="mt-4">
                <Form.Label>
                  Approve Status :{" "}
                  {task?.pdca_ed[0]?.approve_status
                    ? task?.pdca_ed[0]?.approve_status
                    : "No status available"}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  value={
                    task?.pdca_ed[0]?.remarks
                      ? task?.pdca_ed[0]?.remarks
                      : "No notes available"
                  }
                  readOnly
                />
              </div>
            )}
            {task?.is_done === false ? (
              <div className="mt-4">
                <Form.Label>End Document</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setDocumentFile(e.target.files[0])}
                  className="mb-3"
                />
                <Button
                  variant="secondary"
                  onClick={handleUploadAndSubmit}
                  className="px-4 mt-4 mb-3"
                >
                  Upload & Submit
                </Button>
              </div>
            ) : (
              <div>Task has been approved</div>
            )}
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default EmployeePDCAViewDocument;
