/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import notifications from "../../assets/json/notifications";
import message from "../../assets/json/message";
import {
  Applogo,
  Avatar_02,
  Veritical,
  headerlogo,
  lnEnglish,
  lnFrench,
  lnGerman,
  lnSpanish,
} from "../../Routes/ImagePath";

import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  employeeDetails,
  logoutUrl,
} from "../../helpers/endpoints/api_endpoints";
// importing the logout AccountAction Variable
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../store/actions/accountsActions";
import { useSelector } from "react-redux";
import { asyncLogout } from "../../store/actions/accountsActions";
import { debounce } from "lodash"; // Make sure to import lodash
import { Space, Switch } from "antd";
import Logo from "../../assets/img/company/company-05.png";
import { Dropdown } from "react-bootstrap";

const Header = (props) => {
  const [employeeDetail, setEmployeeDetails] = useState();
  const data = notifications.notifications;
  const datas = message.message;
  const [notification, setNotifications] = useState(false);
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [flagImage, setFlagImage] = useState(lnEnglish);
  const [tabRedirect, setTabRedirect] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [toggleDark, setToggleDark] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(true);
  const navigate = useNavigate();

  // dispatch initialise
  const dispatch = useDispatch();

  // Simplified cookie functions
  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/; max-age=31536000`; // 1 year
  };

  const getCookie = (name) => {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    return match ? match[2] : null;
  };

  // Initialize theme state
  const [darkTheme, setDarkTheme] = useState(() => {
    // Check cookie first
    const cookieTheme = getCookie("theme");
    console.log("Initial theme from cookie:", cookieTheme); // Debug log

    if (cookieTheme === "dark") {
      // If dark theme cookie exists, apply it immediately
      document.querySelector("html").setAttribute("data-layout-mode", "dark");
      document.querySelector("html").setAttribute("data-topbar", "dark");
      return true;
    } else {
      // Default to orange theme
      document.querySelector("html").setAttribute("data-layout-mode", "orange");
      document.querySelector("html").setAttribute("data-topbar", "orange");
      return false;
    }
  });

  // Theme toggle functions
  const OrangeThemes = useCallback(() => {
    setDarkTheme(false);
    setCookie("theme", "orange");
    document.querySelector("html").setAttribute("data-layout-mode", "orange");
    document.querySelector("html").setAttribute("data-topbar", "orange");
  }, []);

  const DarkThemes = useCallback(() => {
    setDarkTheme(true);
    setCookie("theme", "dark");
    document.querySelector("html").setAttribute("data-layout-mode", "dark");
    document.querySelector("html").setAttribute("data-topbar", "dark");
  }, []);

  // Effect to handle page loads and visibility changes
  useEffect(() => {
    const handleThemeCheck = () => {
      const currentTheme = getCookie("theme");
      console.log("Current theme from cookie:", currentTheme); // Debug log

      if (currentTheme === "dark" && !darkTheme) {
        setDarkTheme(true);
        document.querySelector("html").setAttribute("data-layout-mode", "dark");
        document.querySelector("html").setAttribute("data-topbar", "dark");
      } else if (currentTheme === "orange" && darkTheme) {
        setDarkTheme(false);
        document
          .querySelector("html")
          .setAttribute("data-layout-mode", "orange");
        document.querySelector("html").setAttribute("data-topbar", "orange");
      }
    };
    const cookies = getCookie("theme");
    // Check theme when component mounts
    const checkCookies = () => {
      if (!cookies) {
        OrangeThemes();
      } else {
        handleThemeCheck();
      }
    };

    checkCookies();

    // Add event listeners
    window.addEventListener("load", handleThemeCheck);
    document.addEventListener("visibilitychange", handleThemeCheck);

    return () => {
      window.removeEventListener("load", handleThemeCheck);
      document.removeEventListener("visibilitychange", handleThemeCheck);
    };
  }, [darkTheme]);

  const debouncedHandleLogout = useCallback(
    debounce(() => {
      if (loggingOut) return; // Prevent multiple logout attempts
      setLoggingOut(true);
      console.log("Logging out...");

      const refreshToken = localStorage.getItem("refreshToken");

      const performLocalLogout = () => {
        return dispatch(asyncLogout()).then(() => {
          setLoggingOut(false);
          localStorage.clear();
          navigate("/");
        });
      };

      if (!refreshToken) {
        return performLocalLogout();
      }

      axiosInstance
        .post(logoutUrl, { refresh_token: refreshToken })
        .then(() => {
          return performLocalLogout();
        })
        .catch((error) => {
          console.error("Logout API error:", error);
          setLoggingOut(false); // Reset loggingOut state on error
          return performLocalLogout();
        });
    }, 300),
    [dispatch, navigate]
  );

  useEffect(() => {
    // Check if access token exists
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        if (response.status === 200) {
          // console.log("Header", response.data);
          setEmployeeDetails(response.data); // Set the employeeDetail state with fetched data
        } else {
          setTabRedirect(true);
        }
      },
      (error) => {
        console.log(error);
        setTabRedirect(true);
      }
    );

    // Apply the initial theme from localStorage
    const savedTheme = localStorage.getItem("colorschema");
    if (savedTheme) {
      const htmlElement = document.querySelector("html");
      htmlElement.setAttribute("data-layout-mode", savedTheme);
      htmlElement.setAttribute("data-topbar", savedTheme);
    }
  }, []);

  useEffect(() => {
    if (tabRedirect && !loggingOut) {
      debouncedHandleLogout();
    }
    const dark = localStorage.getItem("colorschema");
    if (dark === "orange") {
      setDarkTheme(false);
    } else {
      setDarkTheme(true);
    }
  }, [tabRedirect, loggingOut, debouncedHandleLogout]);

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
    setflag(false);
  };

  const handleNotification = () => {
    setNotifications(!notification);
    setflag(false);
    setIsOpen(false);
    setProfile(false);
  };

  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  console.log(pathname);
  const Credencial = localStorage.getItem("credencial");
  const Value = JSON.parse(Credencial);
  const UserName = Value?.email?.split("@")[0];
  const ProfileName = employeeDetail?.employee_name;

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setFlagImage(
      lng === "en"
        ? lnEnglish
        : lng === "fr"
        ? lnFrench
        : lng === "es"
        ? lnSpanish
        : lnGerman
    );
  };

  const ishrvalue = useSelector((state) => state.account.ishrStatus);
  console.log(ishrvalue);

  const isHR = employeeDetail?.employment_details?.find((employee) => {
    if (employee.department_detail.name === "Human Resource") {
      dispatch({ type: "UPDATE_ISHR_STATUS" });
      return true;
    }
    return false;
  });

  const isOperations = employeeDetail?.employment_details?.find((employee) => {
    if (employee.department_detail.name === "Operations") {
      return true;
    }
    return false;
  });

  const isGacl = employeeDetail?.employment_details?.find((employee) => {
    if (employee.department_detail.name === "GACL") {
      return true;
    }
    return false;
  });

  const toggleDropdownMobile = () => {
    console.log(openMobileMenu);
    setOpenMobileMenu((prevState) => !prevState); // Toggle the state
    console.log(openMobileMenu);
  };

  const isTechnical = employeeDetail?.employment_details?.find((employee) => {
    if (
      employee.department_detail.name === "Technical" ||
      employee.department_detail.name === "test department"
    ) {
      return true;
    }
    return false;
  });

  const isHRfalsevalue = employeeDetail?.employment_details?.find(
    (employee) => {
      if (employee.department_detail.name !== "Human Resource") {
        dispatch({ type: "UPDATE_ISHR_STATUS_false" });
        return false;
      }
      return true;
    }
  );

  console.log("Header", isHR);
  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/employee-dashboard" className="logo">
          <img
            src={
              darkTheme
                ? Logo
                : "https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/images/updated%20trans-Habot-logo-png.png"
            }
            alt="img"
            style={{ width: "140px", height: "100px", paddingBottom: 35 }}
          />
        </Link>
        <Link to="/employee-dashboard" className="logo2">
          <img src={Applogo} width={40} height={40} alt="img" />
        </Link>
      </div>
      {/* /Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>HABOT.IO</h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu gap-4 d-none d-lg-flex">
        {/* Search */}
        {isHR?.department_detail?.name === "Human Resource" ? (
          <Link
            to={
              pathname === "/hr-dashboard" ||
              pathname === "/employees" ||
              pathname === "/zii-topic" ||
              pathname === "/zii-reports" ||
              pathname === "/employeeDetails" ||
              pathname === "/training-reports" ||
              pathname === "/training-verification" ||
              pathname === "/add-policies" ||
              pathname === "/zii-dashboard" ||
              new RegExp("^/policy-details/\\d+$").test(pathname) ||
              new RegExp("^/policy-acknowledgement/\\d+$").test(pathname) ||
              new RegExp("^/employment-info/\\d+$").test(pathname) ||
              new RegExp("^/appraisal-report-hr/\\d+$").test(pathname) ||
              pathname === "/appraisal-report" ||
              pathname === "/policy-dashboard" ||
              pathname === "/employee-data-dashboard" ||
              pathname === "/appraisal-dashboard" ||
              pathname === "/training-dashboard" ||
              pathname === "/hr-leaves" ||
              pathname === "/holidays-calendar" ||
              pathname === "/hr-app-report" ||
              pathname === "/holiday-dashboard" ||
              pathname === "/leave-dashboard" ||
              pathname === "/celebrations" ||
              pathname === "/celebrations-dashboard" ||
              pathname === "/hr-attendance" ||
              new RegExp("^/hr-employee-attendance/\\d+$").test(pathname) ||
              pathname === "/attendance-dashboard" ||
              pathname === "/qpa-report"
                ? "/employee-dashboard"
                : "/hr-dashboard"
            }
            className="btn btn-primary"
          >
            {pathname === "/hr-dashboard" ||
            pathname === "/employees" ||
            pathname === "/zii-topic" ||
            pathname === "/employeeDetails" ||
            pathname === "/zii-reports" ||
            pathname === "/training-reports" ||
            pathname === "/training-verification" ||
            pathname === "/add-policies" ||
            pathname === "/zii-dashboard" ||
            new RegExp("^/policy-details/\\d+$").test(pathname) ||
            new RegExp("^/policy-acknowledgement/\\d+$").test(pathname) ||
            new RegExp("^/employment-info/\\d+$").test(pathname) ||
            new RegExp("^/appraisal-report-hr/\\d+$").test(pathname) ||
            pathname === "/appraisal-report" ||
            pathname === "/policy-dashboard" ||
            pathname === "/employee-data-dashboard" ||
            pathname === "/appraisal-dashboard" ||
            pathname === "/training-dashboard" ||
            pathname === "/hr-leaves" ||
            pathname === "/holidays-calendar" ||
            pathname === "/hr-app-report" ||
            pathname === "/holiday-dashboard" ||
            pathname === "/leave-dashboard" ||
            pathname === "/celebrations" ||
            pathname === "/celebrations-dashboard" ||
            pathname === "/hr-attendance" ||
            new RegExp("^/hr-employee-attendance/\\d+$").test(pathname) ||
            pathname === "/attendance-dashboard" ||
            pathname === "/qpa-report" ||
            pathname === "/engine-report"
              ? "Switch to Employee"
              : "Switch to HR"}
          </Link>
        ) : (
          <></>
        )}

        {isOperations?.department_detail?.name === "Operations" ? (
          <Link
            to={
              pathname === "/operations-dashboard" ||
              pathname === "/track-initialization" ||
              new RegExp("^/track-management/\\d+$").test(pathname) ||
              new RegExp("^/assigned-jobs/[\\w%]+/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/approve-job/[\\w%]+/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/end-fields/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/approve-ED/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/predecessor-fields/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/approve-PD/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/PD-element/\\d+/\\d+$").test(pathname) ||
              new RegExp("^/approve-PDElement/\\d+/\\d+$").test(pathname)
                ? "/employee-dashboard"
                : "/operations-dashboard"
            }
            className="btn btn-primary"
          >
            {pathname === "/operations-dashboard" ||
            pathname === "/track-initialization" ||
            new RegExp("^/track-management/\\d+$").test(pathname) ||
            new RegExp("^/assigned-jobs/[\\w%]+/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/approve-job/[\\w%]+/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/end-fields/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/approve-ED/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/predecessor-fields/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/approve-PD/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/PD-element/\\d+/\\d+$").test(pathname) ||
            new RegExp("^/approve-PDElement/\\d+/\\d+$").test(pathname)
              ? "Switch to Employee"
              : "Switch to Operations"}
          </Link>
        ) : (
          <></>
        )}

        {isTechnical?.department_detail?.name === "Technical" ||
        isTechnical?.department_detail?.name === "test department" ? (
          <Link
            to={
              pathname === "/technical-dashboard" ||
              pathname === "/technical-projects" ||
              pathname === "/tech-projects-management" ||
              pathname === "/tech-dashboard" ||
              pathname === "/technical/track-initialisation" ||
              pathname === "/technical/task-dashboard" ||
              new RegExp("^/technical/track-management/\\d+$").test(pathname)
                ? "/employee-dashboard"
                : "/technical-dashboard"
            }
            className="btn btn-primary"
          >
            {pathname === "/technical-dashboard" ||
            pathname === "/technical-projects" ||
            pathname === "/tech-projects-management" ||
            pathname === "/tech-dashboard" ||
            pathname === "/technical/track-initialisation" ||
            pathname === "/technical/task-dashboard" ||
            new RegExp("^/technical/track-management/\\d+$").test(pathname)
              ? "Switch to Employee"
              : "Switch to Technical"}
          </Link>
        ) : (
          <></>
        )}
        {isGacl?.department_detail?.name === "GACL" ? (
          <Link
            to={
              pathname === "/gacl-dashboard" ||
              pathname === "/gacl-add-tasks" ||
              pathname === "/gacl-task-assigned" ||
              pathname === "/board-meeting" ||
              pathname === "/esr-aml-dashboard"
                ? "/employee-dashboard"
                : "/gacl-dashboard"
            }
            className="btn btn-primary"
          >
            {pathname === "/gacl-dashboard" ||
            pathname === "/gacl-add-tasks" ||
            pathname === "/gacl-task-assigned" ||
            pathname === "/board-meeting" ||
            pathname === "/esr-aml-dashboard"
              ? "Switch to Employee"
              : "Switch to GACL"}
          </Link>
        ) : (
          <></>
        )}

        <li className="nav-item dropdown has-arrow main-drop pt-1">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1">
              <img src={Avatar_02} alt="img" />
              <span className="status online" />
            </span>
            <span>{ProfileName ? `${ProfileName}` : "User"}</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            <li
              className="dropdown-item p-0 cursor-pointer"
              to="/"
              onClick={debouncedHandleLogout}
            >
              <button
                aria-label="Logout Button"
                className={`btn btn-transparent btn-sm ${
                  darkTheme ? "text-white" : "text-black"
                }`}
                disabled={loggingOut}
              >
                {loggingOut ? "Logging out..." : "Logout"}
              </button>
            </li>
          </div>
        </li>
      </ul>
      <ul className="nav user-menu d-none d-lg-block ">
        {!darkTheme ? (
          <button
            className="btn btn-outline-secondary rounded-pill mx-2"
            onClick={DarkThemes}
          >
            Switch to Dark <i className="fa fa-moon" />
          </button>
        ) : (
          <button
            className="btn btn-outline-primary rounded-pill mx-2"
            onClick={OrangeThemes}
          >
            Switch to Light <i className="fa fa-sun" />
          </button>
        )}
      </ul>
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu d-block d-lg-none ms-8">
        <Dropdown
          show={!openMobileMenu}
          onToggle={toggleDropdownMobile}
          autoClose="outside"
        >
          <Dropdown.Toggle
            variant="link"
            id="dropdown-custom-components"
            className="text-white"
            onClick={toggleDropdownMobile}
            aria-expanded={openMobileMenu ? "true" : "false"}
          ></Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item as={Link} to="/profile">
              My Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/settings/companysetting">
              Settings
            </Dropdown.Item> */}

            <Dropdown.Item
              as={Link}
              to="/login"
              onClick={debouncedHandleLogout}
              disabled={loggingOut}
            >
              {loggingOut ? "Logging out..." : "Logout"}
            </Dropdown.Item>
            <Dropdown.Divider className="my-0" />
            <Dropdown.Item className="flex items-center">
              Dark Mode{" "}
              <Switch
                checked={darkTheme}
                handleBg={"orange"}
                checkedChildren={<i className="fa fa-sun" />}
                unCheckedChildren={<i className="fa fa-moon ms-2" />}
                onClick={darkTheme ? OrangeThemes : DarkThemes}
                className="ms-1 mb-1"
                style={{
                  backgroundColor: darkTheme ? "#FC646D" : "#34444C",
                  color: darkTheme ? "white" : "black",
                }}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
