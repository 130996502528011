import React, { useState, useEffect } from "react";
import { Card, Table, Button, Typography, Spin } from "antd";
import { Modal as AntModal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeQPA,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";

const { Title, Text } = Typography;

const YourQPA = () => {
  const [qpaData, setQPAData] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQPA, setSelectedQPA] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Fetch current employee details
  useEffect(() => {
    const fetchCurrentEmployee = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setCurrentEmployee(response.data);
        // After getting employee details, fetch their QPA
        fetchQPAData(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchCurrentEmployee();
  }, []);

  const fetchQPAData = async (employeeId, page = 1, pageSize = 10) => {
    try {
      const response = await axiosInstance.get(
        `${employeeQPA}?user=${employeeId}&page=${page}&page_size=${pageSize}`
      );
      setQPAData(response.data.results);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching QPA data:", error);
      toast.error("Failed to fetch QPA data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    fetchQPAData(
      currentEmployee?.employment_details[0].id,
      newPagination.current,
      newPagination.pageSize
    );
  };

  const columns = [
    {
      title: "Sr.No",
      key: "sr_no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Number of Nominees",
      dataIndex: "nominations",
      key: "nominations",
      render: (nominations) => nominations.length,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedQPA(record);
            setIsModalVisible(true);
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 60px)",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <Title level={2}>Your QPA Nominations</Title>
              </div>

              <Card>
                <Table
                  dataSource={qpaData}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  onChange={handleTableChange}
                />
              </Card>

              {/* View Details Modal */}
              <AntModal
                title="QPA Nomination Details"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                  <Button key="close" onClick={() => setIsModalVisible(false)}>
                    Close
                  </Button>,
                ]}
                width={800}
                styles={{
                  body: {
                    maxHeight: "80vh",
                    overflow: "auto",
                  },
                }}
              >
                {selectedQPA && (
                  <div>
                    <Text>
                      Created on:{" "}
                      {new Date(selectedQPA.created_at).toLocaleDateString()}
                    </Text>
                    <div style={{ marginTop: "20px" }}>
                      <Table
                        dataSource={selectedQPA.nominations}
                        pagination={false}
                        columns={[
                          {
                            title: "Nominee Name",
                            dataIndex: ["nominee_details", "employee_name"],
                            key: "name",
                          },
                          {
                            title: "Department",
                            dataIndex: [
                              "nominee_details",
                              "employment_details",
                            ],
                            key: "department",
                            render: (details) =>
                              details[0]?.department_detail?.name,
                          },
                          {
                            title: "Designation",
                            dataIndex: [
                              "nominee_details",
                              "employment_details",
                            ],
                            key: "designation",
                            render: (details) =>
                              details[0]?.designation_detail?.name,
                          },
                          {
                            title: "Status",
                            key: "status",
                            render: (_, record) => (
                              <span>
                                {record.is_accepted
                                  ? record.is_done
                                    ? "Completed"
                                    : "Accepted"
                                  : "Pending"}
                              </span>
                            ),
                          },
                        ]}
                        scroll={{ y: 300, x: 800 }}
                        style={{
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                      />
                    </div>
                  </div>
                )}
              </AntModal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default YourQPA;
