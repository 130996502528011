import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Table,
  Tabs,
  Select,
  Modal,
  Form,
  DatePicker,
  Radio,
  message,
  Input,
  Button,
  Spin,
  Space,
} from "antd";
import {
  CalendarOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  holidays,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import "../../../../../src/assets/css/calendar.css";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Header from "../../../layout/Header";

const { Option } = Select;
const { RangePicker } = DatePicker;

const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

const YearSelector = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={onChange}
    style={{ width: 120, marginRight: 16 }}
  >
    {Object.entries(MONTHS).map(([name, value]) => (
      <Option key={value} value={value}>
        {name.charAt(0) + name.slice(1).toLowerCase()}
      </Option>
    ))}
  </Select>
);

const YEARS = (() => {
  const currentYear = moment().year();
  return Array.from({ length: 5 }, (_, i) => ({
    value: currentYear - 2 + i,
    label: `${currentYear - 2 + i}`,
  }));
})();

const HolidaysCalendar = () => {
  const [weekendsVisible] = useState(true);
  const [activeView, setActiveView] = useState("calendar");
  const [holidayEvents, setHolidayEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [employeeID, setEmployeeID] = useState([]);
  const [form] = Form.useForm();
  const [dateType, setDateType] = useState("single");
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(
    moment().month() + 1
  );
  const [currentCalendarYear, setCurrentCalendarYear] = useState(
    moment().year()
  );
  const calendarRef = useRef(null);
  const [allHolidays, setAllHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHr, setIsHr] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [listViewData, setListViewData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const filterHolidaysForCurrentMonth = useCallback(
    (holidays) => {
      if (!holidays || holidays.length === 0) return;

      // Get the first and last day of the current month's view
      const viewStart = moment(
        new Date(currentCalendarYear, currentCalendarMonth - 1, 1)
      )
        .startOf("month")
        .startOf("week");

      const viewEnd = moment(
        new Date(currentCalendarYear, currentCalendarMonth - 1, 1)
      )
        .endOf("month")
        .endOf("week");

      console.log("Filter Range:", {
        viewStart: viewStart.format("YYYY-MM-DD"),
        viewEnd: viewEnd.format("YYYY-MM-DD"),
        currentMonth: currentCalendarMonth,
        currentYear: currentCalendarYear,
      });

      // Format holidays directly for FullCalendar
      const formattedHolidays = holidays.map((holiday) => ({
        id: holiday.id,
        title: holiday.title,
        start: holiday.start,
        end: holiday.end,
        className: "holiday-event",
        allDay: true,
        extendedProps: {
          ...holiday.extendedProps,
        },
      }));

      console.log("Formatted holidays for calendar:", formattedHolidays);
      setHolidayEvents(formattedHolidays);
    },
    [currentCalendarMonth, currentCalendarYear]
  );

  useEffect(() => {
    if (allHolidays && allHolidays.length > 0) {
      console.log("AllHolidays changed, filtering...", allHolidays);
      filterHolidaysForCurrentMonth(allHolidays);
    }
  }, [allHolidays, filterHolidaysForCurrentMonth]);

  const fetchHolidays = useCallback(async () => {
    setIsLoading(true);
    try {
      // Get previous, current, and next month with proper year handling
      const previousMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1)
        .subtract(1, "month");

      const currentMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1);

      const nextMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1)
        .add(1, "month");

      // Create array of months to fetch (accounting for year changes)
      const monthsToFetch = [
        {
          month: previousMonth.month() + 1,
          year: previousMonth.year(),
        },
        {
          month: currentMonth.month() + 1,
          year: currentMonth.year(),
        },
        {
          month: nextMonth.month() + 1,
          year: nextMonth.year(),
        },
      ];

      console.log(
        "Fetching holidays for months:",
        monthsToFetch.map((m) => `${m.month}/${m.year}`)
      );

      // Fetch holidays for all three months with year parameter
      const responses = await Promise.all(
        monthsToFetch.map(({ month, year }) =>
          axiosInstance.get(`${holidays}?month=${month}&year=${year}`)
        )
      );

      // Merge and sort all holiday data
      const allHolidays = responses
        .flatMap((response) => response.data)
        .sort((a, b) => moment(a.date).diff(moment(b.date)));

      // Group consecutive dates by holiday name using moment
      const mergedHolidays = allHolidays.reduce((groups, holiday) => {
        const currentDate = moment(holiday.date);
        const lastGroup = groups[groups.length - 1];

        if (
          lastGroup &&
          lastGroup.title === holiday.holiday_name &&
          currentDate.diff(moment(lastGroup.end), "days") === 0
        ) {
          // Extend existing group
          lastGroup.end = currentDate.add(1, "days").format("YYYY-MM-DD");
          lastGroup.dates.push(holiday);
        } else {
          // Create new group
          groups.push({
            id: holiday.id,
            title: holiday.holiday_name,
            start: currentDate.format("YYYY-MM-DD"),
            end: currentDate.clone().add(1, "days").format("YYYY-MM-DD"),
            className: "holiday-event",
            extendedProps: {
              type: "holiday",
              user: holiday.user,
              is_done: holiday.is_done,
            },
            dates: [holiday],
          });
        }
        return groups;
      }, []);

      console.log("Merged holidays:", mergedHolidays);
      setAllHolidays(mergedHolidays);
    } catch (error) {
      console.error("Error fetching holidays:", error);
      message.error("Failed to fetch holidays");
    } finally {
      setIsLoading(false);
    }
  }, [currentCalendarMonth, currentCalendarYear]);

  const handleCalendarMonthChange = useCallback(
    (dateInfo) => {
      const firstDayOfMonth = moment(dateInfo.start);
      const newMonth = firstDayOfMonth.month() + 1;
      const newYear = firstDayOfMonth.year();

      console.log("Calendar view changing:", {
        from: `${currentCalendarMonth}/${currentCalendarYear}`,
        to: `${newMonth}/${newYear}`,
        dateInfo,
      });

      setCurrentCalendarMonth(newMonth);
      setCurrentCalendarYear(newYear);
    },
    [currentCalendarMonth, currentCalendarYear]
  );

  // Simplify fetchHolidaysForList function
  const fetchHolidaysForList = useCallback(async (month, year) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${holidays}?month=${month}&year=${year}`
      );
      if (response.data) {
        // Directly set the data from backend
        setListViewData(
          response.data.map((holiday) => ({
            key: holiday.id,
            id: holiday.id,
            title: holiday.holiday_name,
            start: moment(holiday.date).format("MMM D, YYYY"),
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching holidays for list:", error);
      message.error("Failed to fetch holidays");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add handler for year change
  const handleYearChange = useCallback(
    (year) => {
      setSelectedYear(year);
      fetchHolidaysForList(selectedMonth, year);
    },
    [selectedMonth, fetchHolidaysForList]
  );

  // Update handleMonthChange to use selected year
  const handleMonthChange = useCallback(
    (month) => {
      console.log("Month change requested:", month);

      if (activeView === "calendar") {
        const firstDayOfMonth = moment()
          .year(currentCalendarYear)
          .month(month - 1)
          .startOf("month");

        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(firstDayOfMonth.toDate());

        setCurrentCalendarMonth(month);
        setSelectedMonth(month);
        filterHolidaysForCurrentMonth(allHolidays);
      } else {
        setSelectedMonth(month);
        setCurrentCalendarMonth(month);
        fetchHolidaysForList(month, selectedYear);
      }
    },
    [
      currentCalendarYear,
      activeView,
      filterHolidaysForCurrentMonth,
      allHolidays,
      fetchHolidaysForList,
      selectedYear,
    ]
  );

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
        if (
          response.data.employment_details[0].department_detail.name ===
          "Human Resource"
        ) {
          setIsHr(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchHolidays();
  }, [currentCalendarMonth, fetchHolidays]);

  const handleAddHoliday = async (values) => {
    if (employeeID) {
      try {
        const payload = {
          holiday_name: values.holiday_name,
          user: employeeID,
        };

        if (dateType === "single") {
          payload.date = moment(values.date.$d).format("YYYY-MM-DD");
        } else {
          payload.start_date = moment(values.date_range[0].$d).format(
            "YYYY-MM-DD"
          );
          payload.end_date = moment(values.date_range[1].$d).format(
            "YYYY-MM-DD"
          );
        }

        console.log("Payload being sent:", payload);

        await axiosInstance.post(holidays, payload);
        message.success("Holiday added successfully");
        setIsModalVisible(false);
        form.resetFields();
        fetchHolidays();
      } catch (error) {
        console.error("Error adding holiday:", error);
        message.error("Failed to add holiday");
      }
    }
  };

  // Add delete handler function
  const handleDeleteHoliday = useCallback(
    async (holidayId) => {
      try {
        await axiosInstance.delete(`${holidays}${holidayId}/`);
        toast.success("Holiday deleted successfully");

        // Refresh both views
        fetchHolidays(); // Refresh calendar view
        fetchHolidaysForList(selectedMonth, currentCalendarYear); // Refresh list view

        // Close both modals if open
        setSelectedEvent(null); // Close details modal
        setIsModalVisible(false); // Close add holiday modal
      } catch (error) {
        console.error("Error deleting holiday:", error);
        message.error("Failed to delete holiday");
      }
    },
    [fetchHolidays, fetchHolidaysForList, selectedMonth, currentCalendarYear]
  );

  // Update columns definition to include action column
  const columns = [
    {
      title: "Holiday Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          danger
          onClick={() => handleDeleteHoliday(record.id)}
          icon={<DeleteOutlined />}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Update handleViewChange to pass both month and year
  const handleViewChange = useCallback(
    (newView) => {
      setActiveView(newView);

      if (newView === "calendar") {
        // Reset to current month
        const currentDate = moment();
        const currentMonth = currentDate.month() + 1;
        const currentYear = currentDate.year();

        // Update states
        setCurrentCalendarMonth(currentMonth);
        setCurrentCalendarYear(currentYear);
        setSelectedMonth(currentMonth);

        // Update calendar view
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.gotoDate(currentDate.toDate());
        }

        // Refresh calendar data
        filterHolidaysForCurrentMonth(allHolidays);
      } else {
        // When switching to list view, use the current calendar month and year
        setSelectedMonth(currentCalendarMonth);
        fetchHolidaysForList(currentCalendarMonth, currentCalendarYear);
      }
    },
    [
      filterHolidaysForCurrentMonth,
      allHolidays,
      fetchHolidaysForList,
      currentCalendarMonth,
      currentCalendarYear,
    ]
  );

  // Add new handler for month button click
  const handleMonthButtonClick = useCallback(() => {
    // Reset to current month
    const currentDate = moment();
    const currentMonth = currentDate.month() + 1;
    const currentYear = currentDate.year();

    // Update states
    setCurrentCalendarMonth(currentMonth);
    setCurrentCalendarYear(currentYear);

    // Update calendar view
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(currentDate.toDate());
    }

    // Refresh calendar data
    filterHolidaysForCurrentMonth(allHolidays);
  }, [filterHolidaysForCurrentMonth, allHolidays]);

  const eventContent = useCallback((eventInfo) => {
    const event = eventInfo.event;

    const handleEventClick = (e) => {
      e.stopPropagation();
      setSelectedEvent({
        id: event.id,
        title: event.title,
        startDate: moment(event.start),
        endDate: moment(event.end).subtract(1, "day"),
        isMultiDay: !moment(event.start).isSame(
          moment(event.end).subtract(1, "day"),
          "day"
        ),
      });
    };

    return (
      <div className="holiday-event-wrapper" onClick={handleEventClick}>
        <div className="holiday-event-content">
          <span className="holiday-dot"></span>
          <span className="holiday-name">{event.title}</span>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <Header />
      {isHr ? <AdminSideBar /> : <></>}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Holidays</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="admin-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Events</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <div className="d-flex align-items-center">
                  <Tabs
                    defaultActiveKey="calendar"
                    className="view-toggle holiday-tabs"
                    onChange={handleViewChange}
                    size="middle"
                    items={[
                      {
                        key: "calendar",
                        label: (
                          <span className="tab-label">
                            <CalendarOutlined /> Calendar
                          </span>
                        ),
                      },
                      {
                        key: "list",
                        label: (
                          <span className="tab-label">
                            <UnorderedListOutlined /> List
                          </span>
                        ),
                      },
                    ]}
                  />
                  <Button
                    type="primary"
                    className="add-btn ms-3"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <i className="fa-solid fa-plus" /> Add Event
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* Holiday Add Modal */}
          <Modal
            title="Add Holiday"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
          >
            <Form form={form} onFinish={handleAddHoliday} layout="vertical">
              <Form.Item
                name="holiday_name"
                label="Holiday Name"
                rules={[
                  { required: true, message: "Please enter holiday name" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Date Type">
                <Radio.Group
                  onChange={(e) => setDateType(e.target.value)}
                  value={dateType}
                >
                  <Radio value="single">Single Day</Radio>
                  <Radio value="range">Date Range</Radio>
                </Radio.Group>
              </Form.Item>

              {dateType === "single" ? (
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[{ required: true, message: "Please select date" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              ) : (
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  rules={[
                    { required: true, message: "Please select date range" },
                  ]}
                >
                  <RangePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    onChange={(dates) => {
                      if (dates) {
                        console.log("Selected dates:", dates); // For debugging
                      }
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Add Holiday
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          {/* Calendar/List View */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div style={{ marginBottom: 16 }}>
                    {activeView === "calendar" ? null : (
                      <Space>
                        <YearSelector
                          value={selectedMonth}
                          onChange={handleMonthChange}
                        />
                        <Select
                          value={selectedYear}
                          onChange={handleYearChange}
                          style={{ width: 100 }}
                          options={YEARS}
                        />
                      </Space>
                    )}
                  </div>

                  {activeView === "calendar" ? (
                    <Spin spinning={isLoading} tip="Loading holidays...">
                      <div className="calendar-wrapper">
                        <FullCalendar
                          ref={calendarRef}
                          plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                          ]}
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "month",
                          }}
                          customButtons={{
                            month: {
                              text: "month",
                              click: handleMonthButtonClick,
                            },
                          }}
                          initialView="dayGridMonth"
                          editable={false}
                          selectable={true}
                          selectMirror={true}
                          dayMaxEvents={true}
                          weekends={true}
                          events={holidayEvents}
                          datesSet={handleCalendarMonthChange}
                          eventContent={eventContent}
                          eventDisplay="block"
                          eventTimeFormat={{
                            hour: "numeric",
                            minute: "2-digit",
                            meridiem: "short",
                          }}
                          initialDate={moment()
                            .year(currentCalendarYear)
                            .month(currentCalendarMonth - 1)
                            .startOf("month")
                            .format("YYYY-MM-DD")}
                          dayCellClassNames={(data) => {
                            const day = moment(data.date).day();
                            return day === 0 || day === 6 ? "weekend-day" : "";
                          }}
                          dayHeaderClassNames={(data) => {
                            const day = moment(data.date).day();
                            return day === 0 || day === 6
                              ? "weekend-header"
                              : "";
                          }}
                        />
                      </div>
                    </Spin>
                  ) : (
                    <Table
                      className="events-table"
                      columns={columns}
                      dataSource={listViewData}
                      pagination={false}
                      loading={isLoading}
                      locale={{
                        emptyText: "No holidays found for selected month",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {selectedEvent && (
            <Modal
              title="Holiday Details"
              open={!!selectedEvent}
              onCancel={() => setSelectedEvent(null)}
              footer={[
                <Button
                  key="delete"
                  type="primary"
                  danger
                  onClick={() => handleDeleteHoliday(selectedEvent.id)}
                >
                  Delete Holiday
                </Button>,
                <Button key="close" onClick={() => setSelectedEvent(null)}>
                  Close
                </Button>,
              ]}
              styles={{
                header: {
                  borderBottom: "1px solid #f0f0f0",
                  padding: "16px 24px",
                },
                body: {
                  padding: "24px",
                },
                mask: {
                  backgroundColor: "rgba(0, 0, 0, 0.45)",
                },
              }}
            >
              <div className="holiday-details">
                <h3>{selectedEvent.title}</h3>
                <p className="holiday-date">
                  {selectedEvent.isMultiDay ? (
                    <>
                      {selectedEvent.startDate.format("DD MMM")} -{" "}
                      {selectedEvent.endDate.format("DD MMM YYYY")}
                    </>
                  ) : (
                    selectedEvent.startDate.format("DD MMM YYYY")
                  )}
                </p>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default HolidaysCalendar;
