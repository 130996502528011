import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Tag, Typography, Space, Tabs } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import moment from "moment";
import ResignationLetter from "./ResignationLetter";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const ViewEmployeeResignation = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [user, setUser] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });

  useEffect(() => {
    const fetchEmployeeId = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setUser(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Error fetching employee details");
      }
    };

    fetchEmployeeId();
  }, []);

  useEffect(() => {
    if (user) {
      fetchResignations(1, pagination.pageSize);
    }
  }, [user]);

  const fetchResignations = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${separation}?page=${page}&page_size=${pageSize}&user=${user}`
      );
      setResignations(response.data.results);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching resignations:", error);
      toast.error("Error fetching resignations");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchResignations(newPagination.current, newPagination.pageSize);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Submission":
        return "orange";  // Pending state
      case "Reviewed":
        return "cyan";    // Initial review done
      case "Handover":
        return "blue";    // In handover process
      case "Handover Approval":
        return "purple";  // Waiting for final approval
      case "Approved":
        return "green";   // Final approved state
      default:
        return "default"; // Fallback color
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={getStatusColor(status)}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleView(record)}>
          View
        </Button>
      ),
    },
  ];

  const handleView = (record) => {
    setSelectedResignation(record);
    setViewModalVisible(true);
  };

  const ViewModal = () => (
    <Modal
      title="Resignation Details"
      open={viewModalVisible}
      onCancel={() => setViewModalVisible(false)}
      footer={null}
      width={800}
    >
      {selectedResignation && (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Resignation Letter" key="1">
            <Typography>
              <Title level={4}>
                {selectedResignation.user_details.employee_name}'s Resignation
                <Tag 
                  color={getStatusColor(selectedResignation.status)}
                  style={{ marginLeft: '10px' }}
                >
                  {selectedResignation.status}
                </Tag>
              </Title>

              <div
                style={{
                  display: "flex",
                  gap: "24px",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Paragraph style={{ margin: 0 }}>
                  <strong>Department:</strong>{" "}
                  {
                    selectedResignation.user_details.employment_details[0]
                      .department_detail.name
                  }
                </Paragraph>

                <Paragraph style={{ margin: 0 }}>
                  <strong>Designation:</strong>{" "}
                  {
                    selectedResignation.user_details.employment_details[0]
                      .designation_detail.name
                  }
                </Paragraph>

                <Paragraph style={{ margin: 0 }}>
                  <strong>Submitted Date:</strong>{" "}
                  {moment(selectedResignation.created_at).format("DD-MM-YYYY")}
                </Paragraph>
              </div>

              <Title level={5}>Resignation Letter:</Title>
              <Paragraph style={{ whiteSpace: "pre-line" }}>
                {selectedResignation.resignation}
              </Paragraph>

              {selectedResignation.signature && (
                <>
                  <Title level={5}>Signature:</Title>
                  <div
                    style={{
                      maxWidth: "200px",
                      border: "1px solid #f0f0f0",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      src={selectedResignation.signature}
                      alt="Signature"
                      style={{ width: "100%" }}
                    />
                  </div>
                </>
              )}

              {selectedResignation.handover_details && (
                <>
                  <Title level={5}>Handover Person:</Title>
                  <div style={{ marginBottom: '20px' }}>
                    <Paragraph>
                      <strong>Name:</strong> {selectedResignation.handover_details.employee_name}
                    </Paragraph>
                    <Paragraph>
                      <strong>Department:</strong> {
                        selectedResignation.handover_details.employment_details[0]?.department_detail?.name
                      }
                    </Paragraph>
                    <Paragraph>
                      <strong>Designation:</strong> {
                        selectedResignation.handover_details.employment_details[0]?.designation_detail?.name
                      }
                    </Paragraph>
                    {selectedResignation.handover_date && (
                      <Paragraph>
                        <strong>Handover Date:</strong> {
                          moment(selectedResignation.handover_date).format("DD-MM-YYYY")
                        }
                      </Paragraph>
                    )}
                  </div>
                </>
              )}

              {selectedResignation.is_approved && (
                <>
                  <Title level={5}>Approval Details:</Title>
                  <Paragraph>
                    <strong>Approved Date:</strong> {
                      moment(selectedResignation.approved_date).format("DD-MM-YYYY")
                    }
                  </Paragraph>
                </>
              )}
            </Typography>
          </TabPane>

          <TabPane tab="Handover Form" key="2">
            <Typography>
              <Title level={5}>Handover Details</Title>
              {selectedResignation.is_handover ? (
                <div>
                  <Paragraph>
                    <strong>Handover Person:</strong>{" "}
                    {selectedResignation.handover_details?.employee_name ||
                      "Not Assigned"}
                  </Paragraph>
                  {/* Add more handover details here */}
                </div>
              ) : (
                <Paragraph>No handover details available</Paragraph>
              )}
            </Typography>
          </TabPane>

          <TabPane tab="Handover Checklist" key="3">
            <Typography>
              <Title level={5}>Checklist Items</Title>
              {/* Add checklist items here */}
            </Typography>
          </TabPane>

          <TabPane tab="Acceptance Letter" key="4">
            {selectedResignation.status === "Approved" ? (
              <ResignationLetter
                employeeName={selectedResignation.user_details.employee_name}
                user={selectedResignation.user_details.employee_id}
                department={
                  selectedResignation.user_details.employment_details[0]
                    .department_detail.name
                }
                designation={
                  selectedResignation.user_details.employment_details[0]
                    .designation_detail.name
                }
                submissionDate={selectedResignation.created_at}
                // Add more props as needed
              />
            ) : (
              <Paragraph>
                Acceptance letter will be available once the resignation is
                approved.
              </Paragraph>
            )}
          </TabPane>
        </Tabs>
      )}
    </Modal>
  );

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Resignations"
          title="Dashboard"
          subtitle="Resignations"
        />

        <div className="row">
          <div className="col-md-12">
            <Table
              columns={columns}
              dataSource={resignations}
              loading={loading}
              rowKey="id"
              pagination={{
                ...pagination,
                showTotal: (total) => `Total ${total} items`,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>

        <ViewModal />
      </div>
    </div>
  );
};

export default ViewEmployeeResignation;
