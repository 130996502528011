import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Modal } from "react-bootstrap";
import { Table, Spin, Pagination } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  planPhase,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import EmployeeCheckPhase from "./EmployeeCheckPhase";
import EmployeeCheckPhaseMeasure from "./EmployeeCheckPhaseMeasure";
import EmployeeActPhase from "./EmployeeActPhase";
import EmployeeFinalStatus from "./EmployeeFinalStatus";

function EmployeeProblemStatement() {
  const [problemStatements, setProblemStatements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [showMeasureModal, setShowMeasureModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showClosureModal, setShowClosureModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [dataUpdated, setDataUpdated] = useState(false); // Tracks if data is updated

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((err) => console.error("Error fetching employee details:", err));
  }, []);

  useEffect(() => {
    if (employeeID) {
      const fetchProblemStatements = async () => {
        try {
          const url = `https://culturebuilding.habot.io/employee/pdca-form/?user=${employeeID}`;
          const response = await axiosInstance.get(url);
          console.log(response.data);
          setProblemStatements(response.data);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching problem statements:", err);
          setError("Failed to fetch problem statements.");
          setLoading(false);
        }
      };
      fetchProblemStatements();
    }
  }, [employeeID, dataUpdated]);
  // Pass the update function to the modals
  const handleDataUpdate = () => {
    setDataUpdated((prev) => !prev); // Toggle state to trigger re-fetch
  };
  const handleShowModal = (taskId) => {
    setSelectedTaskId(taskId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTaskId(null);
  };

  const handleShowMeasureModal = (statement) => {
    setSelectedTaskId(statement);
    setShowMeasureModal(true);
  };

  const handleCloseMeasureModal = () => {
    setShowMeasureModal(false);
    setSelectedTaskId(null);
  };

  const handleShowActionModal = (statement) => {
    setSelectedTaskId(statement);
    setShowActionModal(true);
  };

  const handleCloseActionModal = () => {
    setShowActionModal(false);
    setSelectedTaskId(null);
  };

  const handleShowClosureModal = (statement) => {
    setSelectedTaskId(statement);
    setShowClosureModal(true);
  };

  const handleCloseClosureModal = () => {
    setShowClosureModal(false);
    setSelectedTaskId(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Problem Statement",
      dataIndex: "problem_statement",
      key: "problem_statement",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => (status ? status : "Pending"),
    },
    {
      title: "Task",
      key: "task",
      align: "center",
      render: (_, record) => (
        <Button onClick={() => handleShowModal(record.id)}>Task</Button>
      ),
    },
    {
      title: "Progress",
      key: "progress",
      align: "center",
      render: (_, record) => (
        <Button onClick={() => handleShowMeasureModal(record)}>
          Measure & Compare
        </Button>
      ),
    },
    {
      title: "Adjust Actions",
      key: "adjust_actions",
      align: "center",
      render: (_, record) => (
        <Button onClick={() => handleShowActionModal(record)}>
          Action & Changes
        </Button>
      ),
    },
    {
      title: "Final Disclosure",
      key: "final_disclosure",
      align: "center",
      render: (_, record) => (
        <Button onClick={() => handleShowClosureModal(record)}>
          Status & Closure
        </Button>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Button>Problem Statement</Button>
            </Form.Group>

            <div className="table-responsive m-t-15">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center py-4">
                  <Spin size="large" />
                </div>
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={problemStatements.results}
                    rowKey={(record) => record.id}
                    pagination={false}
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={problemStatements.results?.length || 0}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </div>
          </Form>

          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Task Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmployeeCheckPhase
                onUpdate={handleDataUpdate}
                taskId={selectedTaskId}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showMeasureModal}
            onHide={handleCloseMeasureModal}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Measure Phase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmployeeCheckPhaseMeasure
                onUpdate={handleDataUpdate}
                taskId={selectedTaskId}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showActionModal}
            onHide={handleCloseActionModal}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Action & Changes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmployeeActPhase
                onUpdate={handleDataUpdate}
                taskId={selectedTaskId}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showClosureModal}
            onHide={handleCloseClosureModal}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Status & Closure</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmployeeFinalStatus
                onUpdate={handleDataUpdate}
                taskId={selectedTaskId}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default EmployeeProblemStatement;
