import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const EmployeeResignationLetter = () => {
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [formData, setFormData] = useState({
    user: null,
    resignation: "",
    status: "Submission",
    signature: null,
  });

  useEffect(() => {
    const fetchEmployeeId = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setEmployeeId(response.data.employment_details[0].id);
        setFormData((prev) => ({
          ...prev,
          user: response.data.employment_details[0].id,
        }));
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Error fetching employee details");
      }
    };

    fetchEmployeeId();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Preview
      const reader = new FileReader();
      reader.onload = () => {
        setSignaturePreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Store file for form submission
      setFormData((prev) => ({
        ...prev,
        signature: file,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.resignation.trim()) {
      toast.error("Please write your resignation letter");
      return;
    }

    if (!formData.signature) {
      toast.error("Please upload your signature");
      return;
    }

    try {
      // First, submit the resignation data without the file
      const jsonPayload = {
        user: formData.user,
        resignation: formData.resignation,
        status: "Submission",
      };

      const jsonResponse = await axiosInstance.post(separation, jsonPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Then, if there's a signature file, submit it separately
      if (formData.signature) {
        const fileFormData = new FormData();
        fileFormData.append("signature", formData.signature);

        await axiosInstance.patch(
          `${separation}${jsonResponse.data.id}/`,
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      console.log("Resignation submitted successfully");
      toast.success("Resignation letter submitted successfully");

      // Reset form
      setFormData((prev) => ({
        ...prev,
        resignation: "",
        signature: null,
      }));
      setSignaturePreview(null);

      // Reset file input
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.error("Error submitting resignation:", error);
      toast.error(
        error.response?.data?.message ||
          error.response?.data?.error ||
          "Error submitting resignation"
      );
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Resignation Form"
            title="Dashboard"
            subtitle="Resignation"
          />

          <div className="row">
            <div className="col-md-12">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="resignationLetter">
                  <Form.Label>Resignation Letter</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.resignation}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        resignation: e.target.value,
                      }))
                    }
                    placeholder="Write your resignation letter here..."
                    style={{
                      resize: "vertical",
                      minHeight: "100px",
                      maxHeight: "500px",
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="signature">
                  <Form.Label>Signature</Form.Label>
                  <Row>
                    <Col xs={9}>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      <Form.Text className="text-muted">
                        Please upload your signature as an image file
                      </Form.Text>
                    </Col>
                  </Row>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "50%",
                      height: "150px",
                      border: "1px solid #ccc",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f8f9fa",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    {signaturePreview ? (
                      <img
                        src={signaturePreview}
                        alt="Uploaded Signature"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <p className="text-muted">No Signature Uploaded</p>
                    )}
                  </div>
                </Form.Group>

                <Button variant="warning" type="submit">
                  Submit Resignation
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeResignationLetter;
