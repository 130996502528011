import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Typography,
  Spin,
  Tag,
  Button,
  Modal,
  Descriptions,
  Select,
  Space,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  QPAform,
  employeeList,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import debounce from "lodash/debounce";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const { Title, Text } = Typography;
const { Option } = Select;

const QPAReport = () => {
  const [qpaData, setQPAData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedNominee, setSelectedNominee] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [employeeSearchText, setEmployeeSearchText] = useState("");
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isHr, setIsHr] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  useEffect(() => {
    fetchQPAData();
  }, []);

  const fetchEmployees = async (searchText) => {
    setIsEmployeeLoading(true);
    try {
      const response = await axiosInstance.get(
        `${employeeList}?employee_name=${searchText}`
      );
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setIsEmployeeLoading(false);
    }
  };
  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  const debouncedFetchEmployees = debounce(fetchEmployees, 500);

  const fetchQPAData = async (page = 1, pageSize = 10) => {
    try {
      let url = `${QPAform}?page=${page}&page_size=${pageSize}`;
      if (selectedQuarter) url += `&quarter=${selectedQuarter}`;
      if (selectedUser) url += `&user=${selectedUser}`;
      if (selectedNominee) url += `&nominee=${selectedNominee}`;
      if (selectedYear) url += `&year=${selectedYear}`;

      const response = await axiosInstance.get(url);
      setQPAData(response.data.results);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching QPA data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    fetchQPAData(newPagination.current, newPagination.pageSize);
  };

  const columns = [
    {
      title: "Nominee",
      dataIndex: ["nominee_details", "employee_name"],
      key: "nominee",
      render: (text, record) => record.nominee_details?.employee_name || "N/A",
    },
    {
      title: "Nominated By",
      dataIndex: ["user_details", "employee_name"],
      key: "submitter",
    },
    {
      title: "Department",
      dataIndex: ["user_details", "employment_details"],
      key: "department",
      render: (details) => details?.[0]?.department_detail?.name || "N/A",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Quarter",
      dataIndex: "quarter",
      key: "quarter",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Tag color={record.declaration ? "green" : "orange"}>
          {record.declaration ? "Completed" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedRecord(record);
            setIsModalVisible(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const renderDetailModal = () => {
    if (!selectedRecord) return null;

    const ratingLabels = {
      1: "Watch finances daily & act to cut losses without remorse.",
      2: "Keep 'Thinking'- jobs in-house & outsource all 'Doing' jobs.",
      3: "Reconstruct & standardize complex tasks and processes including outsourcing, into simple elements & then go accomplish them.",
      4: "Continuously look for money-making opportunities for our platform.",
      5: "Keep an open mind & embrace the diversity of people, ideas & beliefs.",
      6: "Encourage & promote passion and accountability.",
      7: "The understanding of Habot's Vision & Values must be very clear to the whole team.",
      8: "Even if you disagree, express it, and commit to Habot's course while you are a HabotTech",
    };

    const exampleLabels = {
      3: "Describe how the Assessee has taken responsibility for his or her own work.",
      4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities",
      5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles",
      6: "What specific superpowers should the Assesssee be helped out with or provided with?",
      7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'?",
      8: "What stands out about the assessee",
      9: "What are the assessee's strengths?",
      10: "Comments on superpowers as mentioned above",
      11: "What can the Assessee do to develop and grow ",
    };

    return (
      <Modal
        title="QPA Form Details"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedRecord(null);
        }}
        width={900}
        footer={null}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Nominated By">
            {selectedRecord.user_details?.employee_name}
          </Descriptions.Item>
          <Descriptions.Item label="Department">
            {selectedRecord.user_details?.employment_details[0]
              ?.department_detail?.name || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {selectedRecord.type}
          </Descriptions.Item>
          <Descriptions.Item label="Quarter">
            {selectedRecord.quarter}
          </Descriptions.Item>

          <Descriptions.Item label="What 3 specific instances can you provide of how the Assessee contributed to enhancing the team's performance? Please write actual cases in 50 words each.">
            {selectedRecord.instance_item}
          </Descriptions.Item>

          <Descriptions.Item label="Rate the Assessee on each of the following Leadership Principles, using a scale from 1 to 5">
            <Text
              type="secondary"
              style={{ display: "block", marginBottom: "8px" }}
            >
              [5 means the Assesssee fits the principle perfectly and 1 means
              the Assessee does not fit the principle at all]
            </Text>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "8px",
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                <div key={num}>
                  <Text strong>{ratingLabels[num]}: </Text>
                  {selectedRecord[`rating_${num}`]}
                </div>
              ))}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="A - Which two specific LPs did the Assessee focus on and excel at during the quarter under review?">
            <div>1. {selectedRecord.checkbox_1}</div>
            <div>2. {selectedRecord.checkbox_2}</div>
          </Descriptions.Item>

          <Descriptions.Item label="Answer">
            <div style={{ marginBottom: "16px" }}>
              <Text strong>For LP 1:</Text>
              <br />
              {selectedRecord.example_1}
            </div>
            <div>
              <Text strong>For LP 2:</Text>
              <br />
              {selectedRecord.example_2}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[3]}>
            {selectedRecord.example_3}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[4]}>
            {selectedRecord.example_4}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[5]}>
            {selectedRecord.example_5}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[6]}>
            {selectedRecord.example_6}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[7]}>
            {selectedRecord.example_7}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[8]}>
            {selectedRecord.example_8}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[9]}>
            {selectedRecord.example_9}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[10]}>
            {selectedRecord.example_10}
          </Descriptions.Item>

          <Descriptions.Item label={exampleLabels[11]}>
            {selectedRecord.example_11}
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {new Date(selectedRecord.created_at).toLocaleString()}
          </Descriptions.Item>

          <Descriptions.Item label="Status">
            <Tag color={selectedRecord.declaration ? "green" : "orange"}>
              {selectedRecord.declaration ? "Completed" : "Pending"}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    );
  };

  const handleReset = () => {
    setSelectedUser(null);
    setSelectedNominee(null);
    setSelectedQuarter(null);
    setSelectedYear(new Date().getFullYear().toString());
    fetchQPAData(1, pagination.pageSize);
  };

  const handleSearch = async () => {
    setIsSearching(true);
    await fetchQPAData(1, pagination.pageSize);
    setIsSearching(false);
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2024;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
      years.push(year.toString());
    }
    return years;
  };

  const renderFilters = () => (
    <Space style={{ marginBottom: 16 }}>
      <Select
        placeholder="Select Year"
        style={{ width: 120 }}
        value={selectedYear}
        onChange={(value) => {
          setSelectedYear(value);
          fetchQPAData(1, pagination.pageSize);
        }}
        allowClear
      >
        {getYearOptions().map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>

      <Select
        showSearch
        placeholder="Submitted By"
        style={{ width: 200 }}
        value={selectedUser}
        onChange={(value) => {
          setSelectedUser(value);
        }}
        onSearch={(value) => {
          setEmployeeSearchText(value);
          debouncedFetchEmployees(value);
        }}
        filterOption={false}
        allowClear
        loading={isEmployeeLoading}
        options={employees.map((employee) => ({
          label: employee.employee_name,
          value: employee.id,
        }))}
        notFoundContent={isEmployeeLoading ? <Spin size="small" /> : null}
      />

      <Select
        showSearch
        placeholder="Search Nominee"
        style={{ width: 200 }}
        value={selectedNominee}
        onChange={(value) => {
          setSelectedNominee(value);
        }}
        onSearch={(value) => {
          setEmployeeSearchText(value);
          debouncedFetchEmployees(value);
        }}
        filterOption={false}
        allowClear
        loading={isEmployeeLoading}
        options={employees.map((employee) => ({
          label: employee.employee_name,
          value: employee.id,
        }))}
        notFoundContent={isEmployeeLoading ? <Spin size="small" /> : null}
      />

      <Select
        placeholder="Select Quarter"
        style={{ width: 150 }}
        value={selectedQuarter}
        onChange={(value) => {
          setSelectedQuarter(value);
        }}
        allowClear
      >
        <Option value="Quarter 1">Quarter 1</Option>
        <Option value="Quarter 2">Quarter 2</Option>
        <Option value="Quarter 3">Quarter 3</Option>
        <Option value="Quarter 4">Quarter 4</Option>
      </Select>

      <Button type="primary" onClick={handleSearch} loading={isSearching}>
        Search
      </Button>

      <Button onClick={handleReset}>Reset Filters</Button>
    </Space>
  );

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}

      <div className="page-wrapper">
        <div className="content container-fluid">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 60px)",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Title level={2}>QPA Reports</Title>
              {renderFilters()}
              <Card>
                <Table
                  dataSource={qpaData}
                  columns={columns}
                  rowKey={(record) => `${record.user}-${record.created_at}`}
                  pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  onChange={handleTableChange}
                />
              </Card>
              {renderDetailModal()}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QPAReport;
