import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  planPhase,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function EmployeePDCAPlanPhase() {
  const [problemStatement, setProblemStatement] = useState("");
  const [parameters, setParameters] = useState([
    { name: "", target: "", current: "" },
  ]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [employeeID, setEmployeeID] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
        console.log(response.data.employment_details[0].id);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      setProblemStatement("");
      setParameters([{ name: "", target: "", current: "" }]);
      setFormSubmitted(false);
      setErrors({});
    }
  }, [formSubmitted]);

  const handleAddParameter = () => {
    setParameters([...parameters, { name: "", target: "", current: "" }]);
  };

  const handleParameterChange = (index, field, value) => {
    const updatedParameters = [...parameters];
    updatedParameters[index][field] = value;
    setParameters(updatedParameters);
  };

  const handleSave = () => {
    const newErrors = {};

    if (!problemStatement.trim()) {
      newErrors.problemStatement = "Problem Statement is required";
    }

    if (
      parameters.some((param) => !param.name || !param.target || !param.current)
    ) {
      newErrors.parameters = "All fields in parameters are required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = {
        user: employeeID,
        problem_statement: problemStatement,
        pdca_parameter: parameters.map((param) => ({
          parameter_name: param.name,
          improvement_target: parseInt(param.target, 10),
          actual_data: parseInt(param.current, 10),
        })),
      };

      console.log("Form data:", formData);

      axiosInstance
        .post(planPhase, formData)
        .then((response) => {
          console.log(response.data);
          toast.success("PDCA Plan Phase saved successfully");
          setFormSubmitted(true);
          navigate("/pdca-problem-statement");
        })
        .catch((error) => {
          console.error("Error saving PDCA Plan Phase", error);
          toast.error("Error saving PDCA Plan Phase");
        });
    }
  };

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Form>
            <Form.Group className="mb-3">
              <Button>Problem Statement and Target Setting</Button>
              <div>
                <Form.Label className="mt-4">Problem Statement</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={problemStatement}
                  onChange={(e) => setProblemStatement(e.target.value)}
                />
                {errors.problemStatement && (
                  <p className="text-danger">{errors.problemStatement}</p>
                )}
              </div>
            </Form.Group>
            <hr />
            {parameters.map((param, index) => (
              <Row key={index}>
                <Col>
                  <Form.Label className="mt-4">Parameter</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={param.name}
                    onChange={(e) =>
                      handleParameterChange(index, "name", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Label className="mt-4">Improvement Target</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={param.target}
                    onChange={(e) =>
                      handleParameterChange(index, "target", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Label className="mt-4">Current Data</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={param.current}
                    onChange={(e) =>
                      handleParameterChange(index, "current", e.target.value)
                    }
                  />
                </Col>
              </Row>
            ))}

            <Button
              variant="secondary"
              type="button"
              className="mt-4 mb-3"
              onClick={handleAddParameter}
            >
              + Add more
            </Button>
            <div>
              <Button
                variant="warning"
                type="button"
                className="px-4"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EmployeePDCAPlanPhase;
