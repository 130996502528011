import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, Container } from "react-bootstrap";
import { Table, Tabs, Typography, Tag, Select } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeList,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange"; // Pending state
    case "Reviewed":
      return "cyan"; // Initial review done
    case "Handover":
      return "blue"; // In handover process
    case "Handover Approval":
      return "purple"; // Waiting for final approval
    case "Approved":
      return "green"; // Final approved state
    default:
      return "default"; // Fallback color
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    employees,
    onSubmit,
    onEmployeeSelect,
    selectedEmployee,
  }) => (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Resignation Details
          {resignation && (
            <Tag
              color={getStatusColor(resignation.status)}
              style={{ marginLeft: "10px" }}
            >
              {resignation.status}
            </Tag>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resignation && (
          <Typography>
            <Title level={4}>
              {resignation.user_details.employee_name}'s Resignation
            </Title>

            <div
              style={{
                display: "flex",
                gap: "24px",
                marginBottom: "20px",
                flexWrap: "wrap",
              }}
            >
              <Paragraph style={{ margin: 0 }}>
                <strong>Department:</strong>{" "}
                {
                  resignation.user_details.employment_details[0]
                    .department_detail.name
                }
              </Paragraph>

              <Paragraph style={{ margin: 0 }}>
                <strong>Designation:</strong>{" "}
                {
                  resignation.user_details.employment_details[0]
                    .designation_detail.name
                }
              </Paragraph>

              <Paragraph style={{ margin: 0 }}>
                <strong>Submitted Date:</strong>{" "}
                {moment(resignation.created_at).format("DD-MM-YYYY")}
              </Paragraph>
            </div>

            <Title level={5}>Resignation Letter:</Title>
            <Paragraph style={{ whiteSpace: "pre-line" }}>
              {resignation.resignation}
            </Paragraph>

            {resignation.signature && (
              <>
                <Title level={5}>Signature:</Title>
                <div
                  style={{
                    maxWidth: "200px",
                    border: "1px solid #f0f0f0",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src={resignation.signature}
                    alt="Signature"
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}

            <Form.Group className="mt-4">
              <Form.Label>
                <strong>Handover Person</strong>
                {resignation.handover_date && (
                  <small className="text-muted ml-2">
                    (Handover Date:{" "}
                    {moment(resignation.handover_date).format("DD-MM-YYYY")})
                  </small>
                )}
              </Form.Label>
              {resignation.is_reviewed ? (
                <div className="border rounded p-3">
                  <Paragraph>
                    <strong>Name:</strong>{" "}
                    {resignation.handover_details?.employee_name}
                  </Paragraph>
                  <Paragraph>
                    <strong>Department:</strong>{" "}
                    {
                      resignation.handover_details?.employment_details[0]
                        ?.department_detail?.name
                    }
                  </Paragraph>
                  <Paragraph>
                    <strong>Designation:</strong>{" "}
                    {
                      resignation.handover_details?.employment_details[0]
                        ?.designation_detail?.name
                    }
                  </Paragraph>
                  {resignation.is_approved && (
                    <Paragraph>
                      <strong>Approved Date:</strong>{" "}
                      {moment(resignation.approved_date).format("DD-MM-YYYY")}
                    </Paragraph>
                  )}
                </div>
              ) : (
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Search and select employee for handover"
                  optionFilterProp="children"
                  onChange={onEmployeeSelect}
                  value={selectedEmployee}
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  options={
                    employees?.map((emp) => ({
                      value: emp.id,
                      label: `${emp.employee_name} - ${
                        emp.employment_details[0]?.department_detail?.name || ""
                      } - ${
                        emp.employment_details[0]?.designation_detail?.name ||
                        ""
                      }`,
                    })) || []
                  }
                />
              )}
            </Form.Group>
          </Typography>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {resignation?.status === "Submission" && (
          <Button variant="primary" onClick={onSubmit}>
            Submit Handover/Review
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
);

const ViewResignationLetterTL = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });

  // First fetch department ID
  useEffect(() => {
    const fetchDepartmentID = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const deptId = response.data.employment_details[0].department_detail.id;
        setDepartmentID(deptId);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };

    fetchDepartmentID();
  }, []);

  // Only fetch resignations when departmentID is available
  useEffect(() => {
    if (departmentID) {
      fetchResignations(1, pagination.pageSize);
      fetchEmployees();
    }
  }, [departmentID]);

  const fetchResignations = async (page, pageSize) => {
    if (!departmentID) return;

    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${separation}?page=${page}&page_size=${pageSize}&department=${departmentID}`
      );
      setResignations(response.data.results);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching resignations:", error);
      toast.error("Error fetching resignations");
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(employeeList);
      const activeEmployees = response.data.filter(
        (emp) => emp.employment_details[0]?.employment_status === "Active"
      );

      setEmployees(activeEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    if (departmentID) {
      fetchResignations(newPagination.current, newPagination.pageSize);
    }
  };

  const handleView = (record) => {
    setSelectedResignation(record);
    setViewModalVisible(true);
  };

  const handleEmployeeSelect = useCallback((value) => {
    setSelectedEmployee(value);
  }, []);

  const handleModalClose = useCallback(() => {
    setViewModalVisible(false);
    setSelectedEmployee(null);
  }, []);

  const handleSubmitReview = useCallback(async () => {
    if (!selectedEmployee) {
      toast.error("Please select a handover person");
      return;
    }

    try {
      await axiosInstance.patch(`${separation}${selectedResignation.id}/`, {
        handover_person: selectedEmployee,
        status: "Reviewed",
        is_reviewed: true,
      });

      toast.success("Resignation reviewed successfully");
      handleModalClose();
      fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error updating resignation:", error);
      toast.error("Error updating resignation");
    }
  }, [
    selectedEmployee,
    selectedResignation,
    pagination.current,
    pagination.pageSize,
  ]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button variant="primary" onClick={() => handleView(record)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Review Resignations"
          title="Dashboard"
          subtitle="Resignations"
        />

        <div className="row">
          <div className="col-md-12">
            <Table
              columns={columns}
              dataSource={resignations}
              loading={loading || !departmentID}
              rowKey="id"
              pagination={{
                ...pagination,
                showTotal: (total) => `Total ${total} items`,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>

        <ViewModal
          show={viewModalVisible}
          onHide={handleModalClose}
          resignation={selectedResignation}
          employees={employees}
          onSubmit={handleSubmitReview}
          onEmployeeSelect={handleEmployeeSelect}
          selectedEmployee={selectedEmployee}
        />
      </div>
    </div>
  );
};

export default ViewResignationLetterTL;
