import React, { useEffect, useState, useCallback } from "react";
import { Table, Pagination } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  hrCourses,
  employeeDetails,
  enrolledCourses,
  patchEnrolledCourses,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";

const EmployeeCourseList = () => {
  const [showAvailableDetailsModal, setShowAvailableDetailsModal] =
    useState(false);
  const [showEnrolledDetailsModal, setShowEnrolledDetailsModal] =
    useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [enrolledCoursesList, setEnrolledCoursesList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [departmentID, setDepartmentID] = useState(null);
  const [designationID, setDesignationID] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("available");
  const [certificateFile, setCertificateFile] = useState(null);
  const [enrolledPage, setEnrolledPage] = useState(1);
  const [enrolledTotalCount, setEnrolledTotalCount] = useState(0);
  const PAGE_SIZE = 15; // Add this constant for the fixed page size

  const fetchCourses = useCallback(() => {
    if (!departmentID) return;

    let endpoint = `${hrCourses}?department=${departmentID}&designation=${designationID}&is_publish=true&is_enrolled=false`;

    if (searchTerm) {
      endpoint += `&title__icontains=${searchTerm}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDocumentLists(response.data);
        console.log("available data", response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [departmentID, searchTerm, designationID]);

  const fetchEnrolledCourses = useCallback(() => {
    if (!employeeID) return;

    setIsLoading(true);
    let endpoint = `${enrolledCourses}?user=${employeeID}&page=${enrolledPage}`;

    if (searchTerm) {
      endpoint += `&course__title__icontains=${searchTerm}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setEnrolledCoursesList(response.data.results);
        setEnrolledTotalCount(response.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [employeeID, searchTerm, enrolledPage]);

  useEffect(() => {
    fetchCourses();
  }, [departmentID, fetchCourses]);

  useEffect(() => {
    if (activeTab === "enrolled") {
      fetchEnrolledCourses();
    }
  }, [employeeID, fetchEnrolledCourses, activeTab]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setDepartmentID(
          response.data.employment_details[0].department_detail.id
        );
        setEmployeeID(response.data.employment_details[0].id);
        setDesignationID(response.data.employment_details[0].designation);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const formatDuration = (durationString) => {
    if (!durationString) return "";

    let totalHours = 0;
    let totalMinutes = 0;

    if (durationString.includes(" ")) {
      // Case when the string is like "1 01:00:00"
      const [daysPart, timePart] = durationString.split(" ");
      const days = parseInt(daysPart, 10);
      const [hours, minutes] = timePart.split(":").map(Number);

      totalHours = days * 24 + hours;
      totalMinutes = minutes;
    } else {
      // Case when the string is like "21:00:00"
      const [hours, minutes] = durationString.split(":").map(Number);

      totalHours = hours;
      totalMinutes = minutes;
    }

    return `${totalHours} hours ${totalMinutes} minutes`;
  };
  const handleShowAvailableDetails = (record) => {
    setSelectedRecord({
      ...record,
      created: record.created
        ? moment(record.created).format("DD-MM-YYYY hh:mm A")
        : "-",
      due_date: record.due_date
        ? moment(record.due_date).format("DD-MM-YYYY")
        : "-",
      publish_date: record.publish_date
        ? moment(record.publish_date).format("DD-MM-YYYY")
        : "-",
    });
    setFormData(record);
    setShowAvailableDetailsModal(true);
  };

  const handleShowEnrolledDetails = (record) => {
    setSelectedRecord({
      ...record,
      created: record.created
        ? moment(record.created).format("DD-MM-YYYY hh:mm A")
        : "-",
      due_date: record.due_date
        ? moment(record.due_date).format("DD-MM-YYYY")
        : "-",
      publish_date: record.publish_date
        ? moment(record.publish_date).format("DD-MM-YYYY")
        : "-",
    });
    setFormData(record.course_details || {});
    setShowEnrolledDetailsModal(true);
  };

  const handleCloseAvailableDetails = () => {
    setShowAvailableDetailsModal(false);
  };

  const handleCloseEnrolledDetails = () => {
    setShowEnrolledDetailsModal(false);
  };

  const handleEnroll = (courseId) => {
    axiosInstance
      .post(enrolledCourses, {
        user: employeeID,
        course: courseId,
      })
      .then((response) => {
        toast.success("Enrolled successfully!");
        fetchCourses();
        fetchEnrolledCourses();
      })
      .catch((error) => {
        toast.error("Failed to enroll.");
        console.log(error);
      });
  };

  const handleCertificateUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPEG, PNG) are allowed.");
        setCertificateFile(null); // Reset the file state if the file is invalid
        e.target.value = null; // Clear the input file field
        return;
      }

      setCertificateFile(file);
      toast.success("Image file selected successfully.");
    }
  };
  const handleSubmitCertificate = () => {
    if (!certificateFile) {
      toast.error("Please select a certificate file to upload.");
      return;
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("certificate", certificateFile);
    formData.append("is_completed", true);

    axiosInstance
      .patch(patchEnrolledCourses(selectedRecord.id), formData, config)
      .then((response) => {
        toast.success("Certificate uploaded successfully!");
        setCertificateFile(null); // Clear the certificate file state
        fetchEnrolledCourses(); // Refetch enrolled courses to update the state
        handleCloseEnrolledDetails();
      })
      .catch((error) => {
        toast.error("Failed to upload certificate.");
        console.log(error);
      });
  };

  const handleEnrolledPageChange = (newPage) => {
    setEnrolledPage(newPage);
  };

  const availableColumns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Published Date",
      dataIndex: "created",
      key: "created",
      render: (text) => (
        <div>{text ? moment(text).format("DD-MM-YYYY") : "-"}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowAvailableDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
          {activeTab === "available" && (
            <Button
              onClick={() => handleEnroll(record.id)}
              className="btn btn-success"
            >
              Enroll
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Published";
        let backgroundColor = "#4caf50";

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
  ];

  const enrolledColumns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: ["course_details", "title"],
      key: "course_name",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: ["course_details", "platform"],
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Due Date",
      dataIndex: ["course_details", "due_date"],
      key: "dueDate",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowEnrolledDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
          {!record.is_completed && (
            <Button
              onClick={() => handleShowEnrolledDetails(record)}
              className="btn btn-success me-2"
            >
              Submit Certificate
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Enrolled";
        let backgroundColor = "#80bfff";

        if (record.is_completed && !record.is_verified) {
          statusText = "Under Verification";
          backgroundColor = "#ffc107";
        } else if (record.is_completed && record.is_verified) {
          statusText = "Verified";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row mb-0">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Your Courses</h1>
            </div>
          </div>
          <div className="tabs mb-2">
            <div
              className={`tab-item ${
                activeTab === "available" ? "active" : ""
              }`}
              onClick={() => setActiveTab("available")}
            >
              Available Courses
            </div>
            <div
              className={`tab-item ${activeTab === "enrolled" ? "active" : ""}`}
              onClick={() => setActiveTab("enrolled")}
            >
              Enrolled Courses
            </div>
          </div>
          <div className="row mb-10">
            <div className="col-12 pb-2 d-flex align-items-center justify-content-between">
              <div className="col-md-3">
                <div className="input-block mb-1 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Course Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i
                    className="fa fa-search position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                style={{ overflowX: "auto" }}
                columns={
                  activeTab === "available" ? availableColumns : enrolledColumns
                }
                dataSource={
                  activeTab === "available"
                    ? documentLists
                    : enrolledCoursesList
                }
                rowKey={(record) => record.id}
                loading={isLoading}
                pagination={false}
              />
              {activeTab === "enrolled" && (
                <Pagination
                  current={enrolledPage}
                  total={enrolledTotalCount}
                  pageSize={PAGE_SIZE}
                  onChange={handleEnrolledPageChange}
                  showSizeChanger={false}
                  showQuickJumper={false}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              )}
            </div>
          </div>

          {/* Available Courses Modal */}
          <Modal
            show={showAvailableDetailsModal}
            onHide={handleCloseAvailableDetails}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Available Course Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedRecord && (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={formData.title || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Platform</label>
                        <input
                          type="text"
                          className="form-control"
                          name="platform"
                          value={formData.platform || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Description</label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={formData.description || ""}
                          readOnly
                          style={{
                            minHeight: "100px",
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Designations</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.designation_details
                            .map((designation) => designation.name)
                            .join(", ")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Departments</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.department_details
                            .map((department) => department.name)
                            .join(", ")}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-9 d-flex align-items-center">
                      <div className="input-block mb-3 flex-grow-1">
                        <label className="col-form-label">Duration</label>
                        <input
                          type="text"
                          className="form-control"
                          name="duration"
                          value={formatDuration(formData.duration)}
                          readOnly
                        />
                      </div>
                      <div className="ms-3 mt-2">
                        <Button
                          variant="primary"
                          onClick={() =>
                            window.open(formData.course_link, "_blank")
                          }
                        >
                          Redirect to Course
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAvailableDetails}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Enrolled Courses Modal */}
          <Modal
            show={showEnrolledDetailsModal}
            onHide={handleCloseEnrolledDetails}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Enrolled Course Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedRecord && (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Course Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={formData.title || ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Platform</label>
                        <input
                          type="text"
                          className="form-control"
                          name="platform"
                          value={formData.platform || ""}
                          readOnly
                        />
                      </div>{" "}
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Username</label>
                        <input
                          type="text"
                          className="form-control"
                          name="user_name"
                          value={formData.user_name || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Password</label>
                        <input
                          type="text"
                          className="form-control"
                          name="password"
                          value={formData.password || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Due Date</label>
                        <input
                          type="text"
                          className="form-control"
                          name="due_date"
                          value={formData.due_date || ""}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <Button
                          variant="primary"
                          onClick={() =>
                            window.open(formData.course_link, "_blank")
                          }
                          style={{ marginTop: "30px" }}
                        >
                          Redirect to Course
                        </Button>
                      </div>
                    </div>
                    {!selectedRecord.is_completed && (
                      <>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Certificate
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="certificate"
                              onChange={handleCertificateUpload}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-block mb-3">
                            <Button
                              variant="success"
                              onClick={handleSubmitCertificate}
                            >
                              Submit Certificate
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEnrolledDetails}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          justify-content: center;
          margin-bottom: 5px;
          border-bottom: 2px solid #ddd;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 20px;
          color: gray;
          white-space: nowrap;
          margin: 0 200px; /* Add spacing between tabs */
        }
        .tab-item.active {
          color: red;
          border-bottom: 3px solid red;
        }
        @media (max-width: 768px) {
          .tabs {
            flex-direction: column;
            align-items: center;
          }
          .tab-item {
            margin: 5px 0; /* Adjust spacing for smaller screens */
          }
        }
      `}</style>
    </>
  );
};

export default EmployeeCourseList;
