import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import { axiosInstance } from "../../../../helpers/axios/axios";
import { pdcaassigntask } from "../../../../helpers/endpoints/api_endpoints";

function EmployeeActPhase({ taskId, onUpdate }) {
  console.log(taskId);
  const [tasks, setTasks] = useState([]);
  const [commentsMap, setCommentsMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Fetch data from the endpoint
  const fetchTasks = async () => {
    setIsLoading(true);
    console.log(taskId?.id);
    try {
      const response = await axiosInstance.get(
        `https://culturebuilding.habot.io/employee/pdca-task/?pdca_form=${taskId?.id}`
      );
      console.log(response.data);

      const fetchedTasks = response.data.results;
      setTasks(fetchedTasks);

      // Initialize comments map with existing comments
      const initialCommentsMap = fetchedTasks.reduce((acc, task) => {
        acc[task.id] = task.comment || "";
        return acc;
      }, {});
      setCommentsMap(initialCommentsMap);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      console.error(error);
      setIsLoading(false);
    }
  };

  // Handle submit for updating comments
  const handleSubmit = async (taskId) => {
    try {
      await axiosInstance.patch(`${pdcaassigntask}${taskId}/`, {
        comment: commentsMap[taskId],
        id: taskId,
      });
      toast.success("Comment updated successfully");
      fetchTasks(); // Refresh the tasks after update
      onUpdate();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update comment");
    }
  };

  // Handle comment change
  const handleCommentChange = (taskId, value) => {
    setCommentsMap((prev) => ({
      ...prev,
      [taskId]: value,
    }));
  };

  // Fetch tasks on component mount
  useEffect(() => {
    fetchTasks();
  }, [taskId?.id]);

  return (
    <div className="p-3">
      <Form>
        <Form.Group className="mb-3" controlId="resignationLetter">
          <Button>Adjust Actions and Implement Changes</Button>

          {!isLoading ? (
            tasks.length > 0 ? (
              tasks.map((task) => (
                <Row key={task.id} className="mt-4">
                  <Col>
                    <Form.Label>Action Name</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      readOnly
                      value={task.pdca_task}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Action Status</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      readOnly
                      value={task.assignee_task_status}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      value={commentsMap[task.id] || ""}
                      onChange={(e) =>
                        handleCommentChange(task.id, e.target.value)
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Label className="mt-5"></Form.Label>
                    <Button
                      variant="warning"
                      onClick={() => handleSubmit(task.id)}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              ))
            ) : (
              <Row className="mt-4">
                <Col>
                  <div className="d-flex flex-column align-items-center py-4">
                    <span className="mt-2 text-muted">
                      No actions available
                    </span>
                  </div>
                </Col>
              </Row>
            )
          ) : (
            <Row className="mt-4">
              <Col>
                <div className="d-flex flex-column align-items-center py-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <span className="mt-2 text-muted">
                    Loading Adjust Actions and Implement Changes...
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </Form.Group>
      </Form>
    </div>
  );
}

export default EmployeeActPhase;
