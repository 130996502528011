import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  departmentList,
  hrCourses,
  employeeDetails,
  designationList,
} from "../../../../helpers/endpoints/api_endpoints";

const TrainingForm = ({ insideModal }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [courseLink, setCourseLink] = useState("");
  const [cost, setCost] = useState("");
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [employeeID, setEmployeeID] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [isApproved, setIsApproved] = useState(false);
  const [currency, setCurrency] = useState("INR");

  useEffect(() => {
    axiosInstance
      .get(departmentList)
      .then((response) => {
        const departments = response.data.map((dept) => ({
          label: dept.name,
          value: dept.id,
        }));
        setDepartmentOptions(departments);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(designationList)
      .then((response) => {
        const designations = response.data.map((des) => ({
          label: des.name,
          value: des.id,
        }));
        setDesignationOptions(designations);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      setTitle("");
      setDescription("");
      setPlatform("");
      setDepartments([]);
      setDesignations([]);
      setCourseLink("");
      setCost("");
      setHours("00");
      setMinutes("00");
      setSeconds("00");
      setIsApproved(false);
      setFormSubmitted(false);
      setErrors({});
    }
  }, [formSubmitted]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  const handleSave = () => {
    const newErrors = {};
    if (!title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!courseLink.trim()) {
      newErrors.courseLink = "Course Link is required";
    } else if (!validateUrl(courseLink)) {
      newErrors.courseLink = "Please enter a valid URL";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const duration = `${hours}:${minutes}:${seconds}`;
      const formData = {
        user: employeeID,
        title,
        description,
        platform,
        department: departments ? departments.map((dept) => dept.value) : [],
        designation: designations ? designations.map((des) => des.value) : [],
        course_link: courseLink,
        cost,
        duration,
        is_approved: isApproved,
      };

      axiosInstance
        .post(hrCourses, formData)
        .then((response) => {
          console.log("Form data saved", formData);
          toast.success("Form data saved successfully");
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.log("Error saving form data", error);
          toast.error("Error saving form data");
        });
    }
  };

  const handleSelectAll = () => {
    setDepartments(departmentOptions);
  };

  const handleSelectAllDesignations = () => {
    setDesignations(designationOptions);
  };

  const handleCostChange = (value) => {
    const numericValue = value.replace(/[A-Za-z\s]/g, "");

    if (!numericValue) {
      setCost("");
      return;
    }

    setCost(`${numericValue} ${currency}`);
  };

  useEffect(() => {
    if (cost) {
      const numericValue = cost.replace(/[A-Za-z\s]/g, "");
      setCost(`${numericValue} ${currency}`);
    }
  }, [currency]);

  return (
    <>
      <div className={insideModal ? "" : "page-wrapper"}>
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header mb-1">
              <h3>New Training Course</h3>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Enter Details</h4>
                </div>
                <div className="card-body">
                  <div className="wizard">
                    <div className="" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        role="tabpanel"
                        id="step1"
                        aria-labelledby="step1-tab"
                      >
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Title of the Course
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                                {errors.title && (
                                  <span className="text-danger">
                                    {errors.title}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Platform
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={platform}
                                  onChange={(e) => setPlatform(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Course Description
                                </label>
                                <textarea
                                  className="form-control"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  style={{
                                    minHeight: "150px",
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Course Link
                                </label>
                                <input
                                  type="url"
                                  className="form-control"
                                  value={courseLink}
                                  onChange={(e) =>
                                    setCourseLink(e.target.value)
                                  }
                                />
                                {errors.courseLink && (
                                  <span className="text-danger">
                                    {errors.courseLink}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">Cost</label>
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="form-control me-2"
                                    value={cost}
                                    onChange={(e) =>
                                      handleCostChange(e.target.value)
                                    }
                                    placeholder="Enter cost"
                                    style={{ width: "70%" }}
                                  />
                                  <select
                                    className="form-select"
                                    value={currency}
                                    onChange={(e) =>
                                      setCurrency(e.target.value)
                                    }
                                    style={{ width: "30%" }}
                                  >
                                    <option value="INR">INR</option>
                                    <option value="AED">AED</option>
                                    <option value="USD">USD</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-end">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Department
                                </label>
                                <Select
                                  options={departmentOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={departments}
                                  onChange={(selectedOptions) =>
                                    setDepartments(selectedOptions)
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={handleSelectAll}
                                >
                                  Select All
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-end">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Designation
                                </label>
                                <Select
                                  options={designationOptions}
                                  placeholder="Select..."
                                  styles={customStyles}
                                  value={designations}
                                  onChange={(selectedOptions) =>
                                    setDesignations(selectedOptions)
                                  }
                                  isMulti
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-block mb-3">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={handleSelectAllDesignations}
                                >
                                  Select All
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Duration (HH:MM:SS)
                                </label>
                                <div className="d-flex">
                                  <select
                                    className="form-control me-2"
                                    value={hours}
                                    onChange={(e) => setHours(e.target.value)}
                                  >
                                    {Array.from({ length: 100 }, (_, i) => (
                                      <option
                                        key={i}
                                        value={String(i).padStart(2, "0")}
                                      >
                                        {String(i).padStart(2, "0")}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="form-control me-2"
                                    value={minutes}
                                    onChange={(e) => setMinutes(e.target.value)}
                                  >
                                    {Array.from({ length: 60 }, (_, i) => (
                                      <option
                                        key={i}
                                        value={String(i).padStart(2, "0")}
                                      >
                                        {String(i).padStart(2, "0")}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="form-control"
                                    value={seconds}
                                    onChange={(e) => setSeconds(e.target.value)}
                                  >
                                    {Array.from({ length: 60 }, (_, i) => (
                                      <option
                                        key={i}
                                        value={String(i).padStart(2, "0")}
                                      >
                                        {String(i).padStart(2, "0")}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="form-check-label ">
                                  <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={isApproved}
                                    onChange={(e) =>
                                      setIsApproved(e.target.checked)
                                    }
                                  />
                                  Course is already verified
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <Link
                                className="btn btn-primary w-50"
                                onClick={() => handleSave()}
                              >
                                Submit
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingForm;
