import React, { useEffect, useState } from "react";
import { Form, Button, Spinner,Modal } from "react-bootstrap"; 
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  pdcaassigntask,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function EmployeePDCAView() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeID, setEmployeeID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [approvedFile, setApprovedFile] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((err) => console.error("Error fetching employee details:", err));
  }, []);

  useEffect(() => {
    if (employeeID) {
      const fetchTasks = async () => {
        try {
          const response = await axiosInstance.get(
            `https://culturebuilding.habot.io/employee/pdca-task/?assignee=${employeeID}`
          );
          setTasks(response.data.results);
          console.log(response.data);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      fetchTasks();
    }
  }, [employeeID]);

  const handleAccept = async (taskId) => {
    console.log(taskId);
    try {
      await axiosInstance.patch(
        `https://culturebuilding.habot.io/employee/pdca-task/${taskId}/`,
        {
          assignee_task_status: "In Progress",
        }
      );
      toast.success("Task status updated to 'In Progress'");
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId
            ? { ...task, assignee_task_status: "In Progress" }
            : task
        )
      );
    } catch (err) {
      console.error("Error updating task status:", err);
      toast.error("Failed to update task status");
    }
  };

  const handleViewApprovedFile = (task) => {
    const approvedDocument = task.pdca_ed.find(
      (doc) => doc.approve_status === "Approved"
    );
    if (approvedDocument) {
      setApprovedFile(approvedDocument.document);
      setShowModal(true);
    } else {
      toast.error("No approved document found.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setApprovedFile(null);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Button>View Assigned Task</Button>
            </Form.Group>
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Task</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task) => (
                    <tr key={task.id}>
                      <td className="text-center">{task.pdca_task}</td>
                      <td className="text-center">{task.due_date}</td>
                      <td className="text-center">
                        {task.assignee_task_status || "Not Accepted"}
                      </td>
                      <td className="text-center">
                        {task.assignee_task_status === "In Progress" ? (
                          <Link to={`/view-assign-document/${task.id}`}>
                            <Button variant="warning" className="px-4">
                              View
                            </Button>
                          </Link>
                        ) : task.is_done ? (
                          <Button
                            variant="secondary"
                            className="px-4"
                            onClick={() => handleViewApprovedFile(task)}
                          >
                            View Document
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleAccept(task.id)}
                            variant="success"
                          >
                            Accept
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Form>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Approved Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {approvedFile ? (
            <a href={approvedFile} target="_blank" rel="noopener noreferrer">
              Click here to view the approved document
            </a>
          ) : (
            <p>No approved document available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmployeePDCAView;
