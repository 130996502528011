import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  pdcaassigntask,
  UserUrl,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function EmployeePDCADoPhase({ taskId, refreshTasks }) {
  const [employees, setEmployees] = useState([]);
  const [taskEntries, setTaskEntries] = useState([{ assignee: "", Date: "" }]);
  const [task, setTask] = useState("");
  const [problemStatement, setProblemStatement] = useState("");
  const [employeeID, setEmployeeID] = useState(null);
  const [loadingProblemStatement, setLoadingProblemStatement] = useState(true);

  const { id } = useParams();
  console.log(taskId);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(UserUrl);
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
        console.log(response.data.employment_details[0].id);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (employeeID) {
      const fetchProblemStatements = async () => {
        try {
          setLoadingProblemStatement(true);
          const url = `https://culturebuilding.habot.io/employee/pdca-form/?user=${employeeID}`;
          const response = await axiosInstance.get(url);
          console.log("pdca problem statements data", response.data);

          const matchedProblem = response.data.results.find(
            (problem) => problem.id === parseInt(taskId)
          );
          if (matchedProblem) {
            setProblemStatement(matchedProblem.problem_statement);
          }
        } catch (err) {
          console.error("Error fetching problem statements:", err);
        } finally {
          setLoadingProblemStatement(false);
        }
      };
      fetchProblemStatements();
    }
  }, [employeeID, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("form data", taskEntries, task);
      const responses = await Promise.all(
        taskEntries.map((entry) =>
          axiosInstance.post(pdcaassigntask, {
            assignee: parseInt(entry.assignee),
            due_date: entry.Date,
            pdca_form: parseInt(taskId),
            pdca_task: task,
          })
        )
      );

      setTaskEntries([{ assignee: "", Date: "" }]);
      setTask("");
      toast.success("Tasks assigned successfully");
      refreshTasks();
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Failed to assign tasks");
    }
  };

  const handleChange = (index, e) => {
    const newTaskEntries = [...taskEntries];
    newTaskEntries[index][e.target.name] = e.target.value;
    setTaskEntries(newTaskEntries);
  };

  const addMoreFields = () => {
    setTaskEntries([...taskEntries, { assignee: "", Date: "" }]);
  };

  const isFormValid = () => {
    return task && taskEntries.every((entry) => entry.assignee && entry.Date);
  };

  useEffect(() => {
    console.log("value of problem statement", problemStatement);
  }, []);
  return (
    <div>
      <div>
        <div className="content container-fluid">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Button
                style={{
                  backgroundColor: "#FD705C",
                  width: 226,
                  height: 47,
                }}
              >
                Task Assignment Page
              </Button>

              <div>
                <Form.Label className="mt-4">Problem Statement</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  readOnly
                  value={
                    loadingProblemStatement ? "Loading..." : problemStatement
                  }
                />
              </div>

              <div>
                <Form.Label className="mt-4">Task</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="task"
                  value={task}
                  onChange={(e) => setTask(e.target.value)}
                />
              </div>
            </Form.Group>

            {taskEntries.map((entry, index) => (
              <Row key={index}>
                <Col md={4}>
                  <Form.Label className="mt-4">Assignee Name</Form.Label>
                  <Form.Select
                    aria-label="Select employee"
                    name="assignee"
                    value={entry.assignee}
                    onChange={(e) => handleChange(index, e)}
                    className="animated-dropdown"
                    style={{
                      height: 50,
                    }}
                  >
                    <option value="">Select</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.employee_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <Form.Label className="mt-4">Due Date</Form.Label>
                  <Form.Control
                    size="lg"
                    type="date"
                    name="Date"
                    value={entry.Date}
                    onChange={(e) => handleChange(index, e)}
                    style={{
                      height: 50,
                    }}
                  />
                </Col>
              </Row>
            ))}

            {/* Add more button */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Button
                type="submit"
                className="px-5 py-2 mt-4 mb-3"
                style={{
                  backgroundColor: "#FF902E",
                }}
                disabled={!isFormValid()}
              >
                Assign
              </Button>

              <Button
                type="button"
                onClick={addMoreFields}
                variant="secondary"
                style={{
                  width: 150,
                  height: 51,
                }}
              >
                + Add more
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EmployeePDCADoPhase;
