import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { planPhase } from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";

function EmployeeFinalStatus({ taskId, onUpdate }) {
  const [status, setStatus] = useState(""); // State for selected status
  const [currentStatus, setCurrentStatus] = useState(""); // State for current status
  console.log(status);
  // Fetch current status on component mount
  useEffect(() => {
    if (taskId?.id) {
      // Assuming the API endpoint returns the task's current status
      axiosInstance
        .get(`${planPhase}${taskId.id}/`)
        .then((response) => {
          setCurrentStatus(response.data.status || ""); // Set current status
          setStatus(response.data.status || ""); // Set default selected status
        })
        .catch((error) => {
          console.error("Failed to fetch current status:", error);
          toast.error("Failed to fetch current status");
        });
    }
  }, [taskId]);

  // Handle submit for updating status
  const handleSubmit = async () => {
    try {
      await axiosInstance.patch(`${planPhase}${taskId?.id}/`, {
        status: status,
      });
      onUpdate();
      toast.success("Status updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    }
  };

  return (
    <div className="p-3">
      <Form>
        <Form.Group className="mb-3" controlId="resignationLetter">
          <Button variant="primary" className="mb-3">
            Final Status & Closure
          </Button>
          <div>
            <Form.Label className="mt-4">Problem Statement</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              value={taskId?.problem_statement || ""}
              disabled
            />
          </div>
          <Row>
            <Col>
              <Form.Label className="mt-4">PDCA Cycle Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select</option>
                <option value="Closed">Closed</option>
                <option value="Continue">Continue</option>
                <option value="Iterative">Iterative</option>
              </Form.Select>
            </Col>
            <Col></Col>
            <Col>
              {/* <Button variant="secondary" type="button" className="mt-5">
                View Progress
              </Button> */}
            </Col>
          </Row>
        </Form.Group>

        <Button onClick={handleSubmit} variant="warning" className="px-4 mt-2">
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default EmployeeFinalStatus;
