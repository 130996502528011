import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  departmentAppraisal,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Move the weeks array outside of the component to avoid the dependency issue
const weeks = Array.from({ length: 5 }, (_, i) => `Week ${i + 1}`);

const OwnDeptAppraisalReport = () => {
  const currentYear = new Date().getFullYear();
  const [activeYear, setActiveYear] = useState(currentYear);
  const [activeMonthButton, setActiveMonthButton] = useState(
    new Date().getUTCMonth() + 1
  );
  const [departmentID, setDepartmentID] = useState(null);
  const [appraisalData, setAppraisalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setDepartmentID(
          response.data.employment_details[0].department_detail.id
        );
      })
      .catch((error) => {
        console.log("Error fetching employee details", error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchAppraisalData = useCallback(() => {
    if (!departmentID) return;

    setIsLoading(true);
    const monthName = new Date(Date.UTC(2020, activeMonthButton - 1))
      .toLocaleString("default", { month: "long" })
      .toLowerCase();

    axiosInstance
      .get(departmentAppraisal(departmentID, activeYear, monthName))
      .then((response) => {
        setAppraisalData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log("Error fetching appraisal data", error);
        toast.error("Failed to fetch appraisal data.");
      })
      .finally(() => setIsLoading(false));
  }, [departmentID, activeYear, activeMonthButton]);

  useEffect(() => {
    fetchAppraisalData();
  }, [fetchAppraisalData]);

  const handleMonthClick = (monthNumber) => {
    setActiveMonthButton(monthNumber);
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          <div className="row mb-2">
            <div className="col-12 d-flex justify-content-between">
              <h1 className="text-2xl font-bold">
                Department Appraisal Report
              </h1>
              <select
                value={activeYear}
                onChange={(e) => setActiveYear(Number(e.target.value))}
                className="form-select"
                style={{ width: "150px" }}
              >
                {Array.from({ length: 6 }, (_, i) => currentYear + 1 - i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <div className="tabs mb-1">
                {Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (monthNumber) => (
                    <div
                      key={monthNumber}
                      className={`tab-item ${
                        activeMonthButton === monthNumber ? "active" : ""
                      }`}
                      onClick={() => handleMonthClick(monthNumber)}
                    >
                      {new Date(Date.UTC(2020, monthNumber - 1)).toLocaleString(
                        "default",
                        { month: "long" }
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped modern-table">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  {weeks.map((week) => (
                    <th key={week}>
                      {week} <br /> Average
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(appraisalData).map(
                  ([employeeName, weekData]) => (
                    <tr key={employeeName}>
                      <td>{employeeName}</td>
                      {weeks.map((week) => (
                        <td key={week}>{weekData[week]?.average || 0}</td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-800 mb-3">Note</h3>
            <p className="text-sm text-gray-600">
              Weekly average is calculated with these factors:
              <ul className="list-disc list-inside space-y-2 mt-2">
                <li>
                  Percentage or number of the Actions in DCDF data movement in
                  the team track that are being automated
                </li>
                <li>
                  Percentage of own tasks [Automated] DCDF tracks covering their
                  own area [100% automation track for all of the person's job]
                </li>
                <li>
                  Percentage of the friction points covered in dataflow actions
                </li>
                <li>
                  Number of stakeholder specifications met in own team area
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          border-bottom: 2px solid #ddd;
          overflow-x: auto;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 15px;
          color: gray;
          white-space: nowrap;
        }
        .tab-item.active {
          color: #007bff;
          font-weight: bold;
          border-bottom: 3px solid #007bff;
        }
        .modern-table {
          border-collapse: separate;
          border-spacing: 0 10px;
        }
        .modern-table th,
        .modern-table td {
          padding: 12px 15px;
          text-align: center;
        }
        .modern-table th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
        .modern-table tr {
          background-color: #fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        .modern-table tr:hover {
          background-color: #f1f1f1;
        }
        .loader-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.8);
          z-index: 1000;
        }
        .loader {
          width: 48px;
          height: 48px;
          border: 5px solid #fff;
          border-bottom-color: #ff3d00;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default OwnDeptAppraisalReport;
